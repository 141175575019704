import type { FC, MouseEvent } from 'react';
import {
  useCallback, useContext, useState, useEffect,
} from 'react';
// import { useTranslation } from 'react-i18next';
import cloneDeep from 'clone-deep';
import { Menu } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '@/hooks/useTracking';
import MenuItemAccount from './MenuItemAccount';
import { ROUTES } from '@/pages/routes';
import { ItemLineAccount } from '@/components';
import { menuItems } from '@/constants/menuItem';
import { AuthRepository } from '@/repositories';
import { AuthContext } from '@/context/Auth/AuthProvider';
import { MenuItems } from '@/enum/Menu';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { RightsContext } from '@/context/Rights/RightsProvider';
import { AuthState } from '@/context/Auth/AuthState';
// import GetUserRightsUseCase from '@/usecases/GetUserRightsUseCase';
// import { SubscriptionStatus } from '@/enum/subscriptionStatus';
// import classes from './styles.module.scss';
import { RightsState } from '@/context/Rights/RightsState';

type Props = {
  open: boolean,
  anchorEl: null | HTMLElement,
  closeMenu: (event: MouseEvent<HTMLElement>) => void;
};

const MenuAccount: FC<Props> = ({ open, anchorEl, closeMenu }) => {
  const navigateTo = useNavigate();
  // const { t } = useTranslation();
  const { clickEvent } = useTracking();

  const { logOut, isAuth } = useContext(AuthContext);
  const { isActive } = useContext(RightsContext);

  const [memberId, setMemberId] = useState<string | null>();
  // const [inactiveUserWpc, setInactiveUserWpc] = useState<boolean>(false);

  const getMenuItems = () => {
    if (isActive === RightsState.ACTIVE) return menuItems;
    const items = cloneDeep(menuItems);
    items.splice(0, 1);
    if (isAuth === undefined || isAuth === AuthState.DISCONNECTED) items.splice(2, 1);
    return items;
  };

  useEffect(() => {
    (async () => {
      const sub = await AuthRepository.getMemberId();
      setMemberId(sub);
      // const rights = await GetUserRightsUseCase.execute();
      // if (rights === SubscriptionStatus.INACTIVE) setInactiveUserWpc(true);
    })();
  }, []);

  const goTo = useCallback(async (enumValue: string) => {
    switch (enumValue) {
      case MenuItems.PACKAGE:
        window.location.href = `${REACT_APP_ZIQY}?page=ACCOUNT&member_id=${memberId}`;
        break;
      case MenuItems.FAQ:
        clickEvent(MATOMO_CATEGORY.ACCOUNT.FAQ);
        navigateTo(ROUTES.FAQ);
        break;
      case MenuItems.DECATHLON_ACCOUNT:
        clickEvent(MATOMO_CATEGORY.ACCOUNT.DECATHLON_ACCOUNT);
        window.open(REACT_APP_DECATHLON_ACCOUNT, '_blank');
        break;
      case MenuItems.LOGOUT:
        clickEvent(MATOMO_CATEGORY.ACCOUNT.LOGOUT);
        await logOut();
        break;
      case MenuItems.ENGAGEMENT:
        clickEvent(MATOMO_CATEGORY.ACCOUNT.ENGAGEMENT);
        navigateTo(ROUTES.ENGAGEMENT);
        break;
      default:
        console.warn('A implémenter');
    }
  }, [logOut, navigateTo, clickEvent, memberId]);

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: { borderRadius: 3 },
      }}
    >
      {/* { isActive !== RightsState.ACTIVE && (
        <MenuItemAccount
          onClick={() => {}}
          isLast={false}
        >
          <WPCButton
            variant="primary"
            className={classes.button_subscription}
            onAction={() => navigateTo(ROUTES.SUBSCRIPTION_SELECTION)}
          >
            { t(`actions.${inactiveUserWpc ? 'continue_subscription' : 'discover_subscription'}`) }
          </WPCButton>
        </MenuItemAccount>
      )} */}
      { getMenuItems().map((item, index) => (
        <MenuItemAccount
          key={index}
          onClick={() => { goTo(item.enum); }}
          isLast={item.isLast}
        >
          <ItemLineAccount
            firstIcon={item.firstIcon}
            text={item.text}
            firstImg={item.firstImg}
            secondIcon={item.secondIcon}
          />
        </MenuItemAccount>
      ))}
    </Menu>
  );
};

export default MenuAccount;
