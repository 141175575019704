import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';

export type LockerItem = {
  uuid: string,
  brandLabel: string,
  webLabel: string,
  cover: string,
  state: string,
  modelCode: string,
  itemId: string,
  supportUrl: string,
  colorLabel: string,
  superModelCode: string;
  price: number,
  validatedAt: string,
  returnedAt: string,
  collectedAt: string,
  inPreparationAt: string,
  shippingAt: string,
  size?: string,
};

export type LockerHistory = {
  total: number,
  items: LockerItem[],
};

export type ReturnInfos = {
  satisfaction: RadioValue;
  usage: RadioValue;
  time: RadioValue;
  complete: boolean;
  clean: boolean;
  goodState: boolean;
};

export type RadioValue = {
  value: string | boolean;
  iconLeft?: VitamixId;
};

export type LockerItemFromBack = {
  uuid: string;
  brand_label: string;
  web_label: string;
  state: string;
  model_code: string;
  super_model_code: string;
  item_id: string;
  pack_shot: PackShot[];
  price: number;
  validated_at: string;
  returned_at: string;
  collected_at: string;
  start_preparation_at: string;
  start_shipping_at: string;
  support_url: string;
  color_label: string;
  size: string[],
  item_ids: string[],
};

export type PackShot = {
  small_url: string;
  original_url: string;
  medium_url: string;
  big_url: string;
};

export type LockerHistoryFromBack = {
  total: number;
  items: LockerItemFromBack[];
};

export type ReturnItem = {
  locker_items_uuid: string;
  satisfaction: string | boolean;
  is_complete: boolean;
  is_clean: boolean;
  looks_fine: boolean;
  duration: string;
  usage: string;
};

export const SATISFACTION_VALUES: RadioValue[] = [
  {
    value: true,
    iconLeft: 'thumb-up-line',
  },
  {
    value: false,
    iconLeft: 'thumb-down-line',
  },
];

export const USAGE_VALUES: RadioValue[] = [
  { value: '0' },
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5' },
  { value: '6' },
  { value: '7+' },
];

export const TIME_VALUES: RadioValue[] = [
  { value: '<1' },
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5+' },
];

export type CreateUserSelection = {
  superModelCode: string,
  modelCode: string,
  itemId: string,
  quantity: number,
};
