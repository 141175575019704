import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import classes from './styles.module.scss';
import { Icon } from '@/components';

type QuantityProps = {
  quantityMin: number,
  quantityMax: number,
  onUpdateQuantity: (quantity: number) => void,
  initialQuantity?: number,
  disabled: boolean,
};

const Quantity: FC<QuantityProps> = ({
  quantityMin,
  quantityMax,
  onUpdateQuantity,
  initialQuantity,
  disabled,
}) => {
  const { t } = useTranslation();

  const [quantitySelected, setQuantitySelected] = useState<number>(initialQuantity || 1);

  useEffect(() => {
    onUpdateQuantity(quantitySelected);
  }, [quantitySelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialQuantity) setQuantitySelected(initialQuantity);
  }, [initialQuantity]);

  function updateQuantity(addQuantity: number) {
    if ((addQuantity === -1 && quantitySelected <= quantityMin) || (addQuantity === 1 && quantitySelected >= quantityMax)) return;
    setQuantitySelected(quantitySelected + addQuantity);
  }

  return (
    <div>
      <div className={classNames('vtmn-typo_text-2', classes.quantity_information)}>{t('quantity.quantity')}</div>
      <div className={classNames(classes.quantity_card, disabled && classes.disabled)}>
        <button className={classes.button_quantity} onClick={() => updateQuantity(-1)}>
          <Icon
            className={classNames(classes.icon, { [classes.disabled]: disabled || quantitySelected <= quantityMin })}
            value="subtract-line"
            variant="default"
          />
        </button>
        <div className={classNames('vtmn-typo_text-2', classes.quantity)}>{quantitySelected}</div>
        <button className={classes.button_quantity} onClick={() => updateQuantity(1)}>
          <Icon
            className={classNames(classes.icon, { [classes.disabled]: disabled || quantitySelected >= quantityMax })}
            value="add-line"
            variant="default"
          />
        </button>
      </div>
    </div>
  );
};

export default Quantity;
