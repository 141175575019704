import type { FC } from 'react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReturnProduct from './ReturnProduct';
import Success from './Success';
import type { ReturnInfos } from '@/types/Locker';
import ReturnEquipmentsUseCase from '@/usecases/ReturnEquipmentsUseCase';
import { ReturnContext } from '@/context/Return/ReturnProvider';
import { ROUTES } from '../routes';
import { ErrorManagementContext } from '@/context/ErrorManagement/ErrorManagementProvider';

const Return: FC = () => {
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [returnInfos, setReturnInfos] = useState<ReturnInfos>();

  const { itemToReturn } = useContext(ReturnContext);
  const { setIsError } = useContext(ErrorManagementContext);

  const navigateTo = useNavigate();

  const next = (infos: ReturnInfos) => {
    setReturnInfos(infos);
    setOpenSuccess(true);
  };

  const previous = () => {
    navigateTo(ROUTES.LOCKER);
  };

  const save = async () => {
    try {
      setOpenSuccess(false);
      if (itemToReturn && returnInfos) {
        await ReturnEquipmentsUseCase.execute({ itemToReturn, returnInfos });
        navigateTo(ROUTES.LOCKER);
      }
    } catch {
      setIsError(true);
    }
  };

  return (
    <div>
      {itemToReturn && (
        <>
          <ReturnProduct
            itemToReturn={itemToReturn}
            infos={returnInfos}
            onBack={previous}
            onValid={next}
          />
          {returnInfos && (
            <Success
              open={openSuccess}
              onValid={save}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Return;
