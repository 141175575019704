import type { FC } from 'react';
import classes from './styles.module.scss';

type ImagePrincipalProps = {
  url: string,
};

const SpotlightImage: FC<ImagePrincipalProps> = ({ url }) => (
  <div className={classes.root}>
    <img src={url} alt="ProductPackShot" />
  </div>
);

export default SpotlightImage;
