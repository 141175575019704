import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { Subscription } from '@/components';
import { useResponsive } from '@/hooks/useResponsive';
import classes from './styles.module.scss';
import Carousel from './Carousel';
import type { Sub } from '@/types/Config';

type SubscriptionListProps = {
  onSelect: (index: number) => void;
  defaultSub: number;
  subs : Sub[];
  nbPlacesAvailable: number,
};

const SubscriptionList: FC<SubscriptionListProps> = ({
  onSelect, defaultSub, subs, nbPlacesAvailable,
}) => {
  const { isMobile } = useResponsive();
  const [selectedIndex, setSelectedIndex] = useState(defaultSub);

  useEffect(() => {
    if (defaultSub !== -1) handleSelect(defaultSub);
  }, [defaultSub]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = (index: number) => {
    setSelectedIndex(index);
    onSelect(index);
  };

  return (
    <div>
      {isMobile ? (
        <Carousel subscriptions={subs} handleSelect={handleSelect} selectedIndex={selectedIndex} nbPlacesAvailable={nbPlacesAvailable} />
      ) : (
        <div className={classes.root}>
          {subs.map((sub, index) => (
            <Subscription
              isSelectable={nbPlacesAvailable > 0}
              key={`sub_${sub.name}`}
              sub={sub}
              onSelect={() => handleSelect(index)}
              isSelected={selectedIndex === index}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SubscriptionList;
