import type { FC } from 'react';
import { Divider, Grid } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PromotionalPriceAndPrice, Spacer } from '@/components';
import classes from './styles.module.scss';
import dateFormatter from '@/utils/dateFromatter';
import type { LockerItem } from '@/types/Locker';

type HistoryLineProps = {
  item: LockerItem,
  onClick: () => void,
  index: number,
  historyLength: number,
};

const HistoryLine : FC<HistoryLineProps> = ({
  item,
  onClick,
  index,
  historyLength,
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      md={12}
      onClick={onClick}
      className={classes.ligne}
    >
      <div className={classNames(classes.ligne_model, index === 0 && classes.first, index + 1 === historyLength && classes.last)}>
        <Grid container>
          <Grid
            item
            xs={9}
            className={classes.img_text}
            onClick={() => {}}
          >
            <div className={classes.image_model}>
              <img src={item.cover} alt="img_model" />
            </div>
            <Spacer horizontal={2} />
            <div>
              <div className="vtmn-typo_text-3 vtmn-font-bold">
                { item.brandLabel }
              </div>
              <Spacer vertical={0.5} />
              <div className={classNames('vtmn-typo_caption-1', classes.label)}>
                {item.webLabel} - { item.colorLabel}
              </div>
              <Spacer vertical={0.5} />
              <div className={classNames('vtmn-typo_caption-1', classes.date)}>
                { t('locker.history.from', { fromDate: dateFormatter(item.validatedAt) })}
                <span className={classes.to_date}>{ t('locker.history.to', { toDate: dateFormatter(item.returnedAt) })}</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={3} className={classes.price}>
            <PromotionalPriceAndPrice realPrice={item.price} displayLabel={false} />
          </Grid>
        </Grid>
      </div>
      { index + 1 < historyLength && <Divider /> }
    </Grid>
  );
};

export default HistoryLine;
