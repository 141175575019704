import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import { BackButton, Spacer, WPCButton } from '@/components';
import ProductSummary from '../ProductSummary';
import Page1 from '../Page1';
import Page2 from '../Page2';
import type { LockerItem, RadioValue, ReturnInfos } from '@/types/Locker';

type ReturnProductProps = {
  itemToReturn: LockerItem;
  onBack: () => void;
  onValid: (infos: ReturnInfos) => void;
  infos?: ReturnInfos;
};

const ReturnProduct: React.FC<ReturnProductProps> = ({
  itemToReturn,
  onBack,
  onValid,
  infos,
}) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [satisfaction, setSatisfaction] = useState<RadioValue | undefined>();
  const [usage, setUsage] = useState<RadioValue | undefined>();
  const [time, setTime] = useState<RadioValue | undefined>();
  const [complete, setComplete] = useState<boolean | undefined>();
  const [clean, setClean] = useState<boolean | undefined>();
  const [state, setState] = useState<boolean | undefined>();

  useEffect(() => {
    if (infos) {
      setSatisfaction(infos ? infos.satisfaction : undefined);
      setUsage(infos ? infos.usage : undefined);
      setTime(infos ? infos.time : undefined);
      setComplete(infos ? infos.complete : undefined);
      setClean(infos ? infos.clean : undefined);
      setState(infos ? infos.goodState : undefined);
    }
  }, [infos]);

  const onBackPage = () => {
    if (currentPage === 1) setCurrentPage(0);
    else onBack();
  };

  const clear = () => {
    setSatisfaction(undefined);
    setUsage(undefined);
    setTime(undefined);
    setComplete(undefined);
    setClean(undefined);
    setState(undefined);
    setCurrentPage(0);
  };

  const onNext = () => {
    if (currentPage === 0) setCurrentPage(1);
    if (currentPage === 1) {
      if (
        satisfaction
        && usage
        && time
        && complete !== undefined
        && clean !== undefined
        && state !== undefined
      ) {
        onValid({
          satisfaction,
          usage,
          time,
          complete,
          clean,
          goodState: state,
        });
        clear();
      }
    }
  };

  const isEnabled = () => {
    if (currentPage === 0 && satisfaction && usage && time) return true;
    return currentPage === 1
      && complete !== undefined
      && clean !== undefined
      && state !== undefined;
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <BackButton onBack={onBackPage} />
          <span className="vtmn-typo_title-3">{t('return.title')}</span>
        </div>
        <Spacer vertical={3} />
        <ProductSummary
          brand={itemToReturn.brandLabel}
          name={itemToReturn.webLabel}
          size={itemToReturn.size}
          price={itemToReturn.price}
          packshot={itemToReturn.cover}
        />
        <Spacer vertical={3} />
        {currentPage === 0 ? (
          <Page1
            satisfaction={satisfaction}
            usage={usage}
            time={time}
            setSatisfaction={setSatisfaction}
            setUsage={setUsage}
            setTime={setTime}
          />
        ) : (
          <Page2
            complete={complete}
            clean={clean}
            state={state}
            setComplete={setComplete}
            setClean={setClean}
            setState={setState}
          />
        )}
        <Spacer vertical={3} />
        <WPCButton
          variant="primary"
          onAction={onNext}
          disabled={!isEnabled()}
          size="stretched"
        >
          {t('actions.continue')}
        </WPCButton>
        <Spacer vertical={4} />
      </div>
    </div>
  );
};

export default ReturnProduct;
