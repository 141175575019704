import type { FC } from 'react';
import { VtmnChip } from '@vtmn/react';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';

type Props = {
  onClick: () => void,
  text: string,
  selected: boolean,
  icon?: VitamixId,
  disabled?: boolean,
};

const Chip : FC<Props> = ({
  onClick, text, selected, icon, disabled = false,
}) => (
  <VtmnChip
    onClick={onClick}
    selected={selected}
    icon={icon}
    disabled={disabled}
  >
    { text }
  </VtmnChip>
);

export default Chip;
