import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { Model as ModelType } from '@/types/Model';
import classes from './styles.module.scss';
import ProductCardInfo from './ProductCardInfo';
import { Spacer } from '@/components';
import { useResponsive } from '@/hooks/useResponsive';
import type { Bundle as BundleType } from '@/types/Bundle';
import { Bundle } from './Bundle';
import type { CreateUserSelection } from '@/types/Locker';

type ProductInformationsProps = {
  model: ModelType,
  setPropertyGenericInfo: (property: string) => void,
  bundle?: BundleType;
  onClickAddSelection: (infos: CreateUserSelection | CreateUserSelection[], model: ModelType[]) => void,
};

const ProductInformations: FC<ProductInformationsProps> = ({
  model,
  setPropertyGenericInfo,
  bundle,
  onClickAddSelection,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div className={classes.root}>
      { isMobile && bundle && <Bundle onClickAddSelection={onClickAddSelection} bundle={bundle} />}
      <Spacer vertical={isMobile ? 3 : 2} />
      <div className="vtmn-font-bold vtmn-typo_title-4">
        {t('product.product_detail')}
      </div>
      <div className={classNames('vtmn-typo_caption-1 vtmn-font-normal', classes.product_id)}>
        {t('product.id', { id: model.modelCode })}
      </div>
      <div className={classes.designed_product_plus}>
        { model.catchLine }
      </div>
      <div className={classes.designed_product_plus}>
        { model.designedFor }
      </div>
      <Spacer vertical={6} />
      {!isMobile && bundle && <Bundle onClickAddSelection={onClickAddSelection} bundle={bundle} />}
      <ProductCardInfo model={model} setPropertyGenericInfo={setPropertyGenericInfo} />
    </div>
  );
};

export default ProductInformations;
