import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { Shot } from '@/types/Packshot';
import classes from './styles.module.scss';
import { Icon } from '@/components';

type ImagesProps = {
  shots: Shot[],
  indexSelect: number,
  onClick: (index: number) => void;
};

const Thumbnails: FC<ImagesProps> = ({ shots, indexSelect, onClick }) => {
  const [isTop, setIsTop] = useState<boolean>(true);
  const [isBottom, setIsBottom] = useState<boolean>(true);

  function goToBottom() {
    const imgDiv = document.getElementById('Thumbnails');
    if (!imgDiv) return;
    imgDiv.scrollTo({ top: imgDiv.scrollHeight, behavior: 'smooth' });
  }

  function goToTop() {
    const imgDiv = document.getElementById('Thumbnails');
    if (!imgDiv) return;
    imgDiv.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    const div = document.getElementById('Thumbnails');
    const scroll = document.querySelector('#Thumbnails');
    if (!div || !scroll) {
      setIsBottom(false);
      return;
    }

    setTimeout(() => {
      if (div.scrollHeight !== div.clientHeight) {
        setIsBottom(false);
      }
    }, 500);

    scroll.addEventListener('scroll', () => {
      scroll.scrollTop <= 0 ? setIsTop(true) : setIsTop(false);
      div.scrollTop + div.offsetHeight >= div.scrollHeight ? setIsBottom(true) : setIsBottom(false);
    });
  }, []);

  return (
    <div className={classes.root} id="Thumbnails">
      { !isTop
        ? (
          <div
            className={`${classes.floatButton} ${classes.floatButtonTop}`}
            onClick={goToTop}
          >
            <Icon
              className={classes.icon}
              value="chevron-up-fill"
              size={32}
              variant="default"
            />
          </div>
        ) : null}
      <div className={classes.thumbnails}>
        {shots.map((shot, index) => (
          <button className={classes.imageSize} onClick={() => onClick(shot.index)} key={index}>
            <img
              className={`${shot.index === indexSelect ? classes.borderSelect : ''}`}
              src={shot.url}
              alt="MiniatureImg"
            />
          </button>
        ))}
      </div>
      { !isBottom
        ? (
          <div
            className={`${classes.floatButton} ${classes.floatButtonBottom}`}
            onClick={goToBottom}
          >
            <Icon
              className={classes.icon}
              value="chevron-down-fill"
              size={32}
              variant="default"
            />
          </div>
        ) : null}
    </div>
  );
};

export default Thumbnails;
