import type React from 'react';
import classnames from 'classnames';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import { Icon } from '@/components';
import classes from './styles.module.scss';

type RadioButtonProps = {
  text: string | boolean;
  iconLeft?: VitamixId;
  selected: boolean;
  background?: 'default' | 'white';
  onSelect: () => void;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  text,
  iconLeft,
  selected,
  background = 'default',
  onSelect,
}) => (
  <button
    className={classnames(classes.root, classes[background], {
      [classes.selected]: selected,
    })}
    onClick={onSelect}
  >
    {iconLeft && <Icon value={iconLeft} size={20} /> }
    <span className={classnames('vtmn-typo_text-2', selected && 'vtmn-text-content-action vtmn-font-bold')}>
      {text}
    </span>
  </button>
);

export default RadioButton;
