import type { FC } from 'react';
import {
  useCallback, useContext, useState, useEffect,
} from 'react';
import cloneDeep from 'clone-deep';
// import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import { menuItems } from '@/constants/menuItem';
import { ROUTES } from '@/pages/routes';
import { ItemLineAccount } from '@/components';
import classes from './styles.module.scss';
import { AuthRepository } from '@/repositories';
import { AuthContext } from '@/context/Auth/AuthProvider';
import { MenuItems } from '@/enum/Menu';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { RightsContext } from '@/context/Rights/RightsProvider';
import { AuthState } from '@/context/Auth/AuthState';
// import GetUserRightsUseCase from '@/usecases/GetUserRightsUseCase';
// import { SubscriptionStatus } from '@/enum/subscriptionStatus';
import { RightsState } from '@/context/Rights/RightsState';
import { useTracking } from '@/hooks/useTracking';

const MobileAccount: FC = () => {
  const navigateTo = useNavigate();
  // const { t } = useTranslation();
  const { clickEvent } = useTracking();

  const { logOut, isAuth } = useContext(AuthContext);
  const { isActive } = useContext(RightsContext);

  const [memberId, setMemberId] = useState<string | null>();
  // const [inactiveUserWpc, setInactiveUserWpc] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const sub = await AuthRepository.getMemberId();
      setMemberId(sub);
      // const rights = await GetUserRightsUseCase.execute();
      // if (rights === SubscriptionStatus.INACTIVE) setInactiveUserWpc(true);
    })();
  }, []);

  const getMenuItems = () => {
    if (isActive === RightsState.ACTIVE) return menuItems;
    const items = cloneDeep(menuItems);
    items.splice(0, 1);
    if (isAuth === undefined || isAuth === AuthState.DISCONNECTED) items.splice(2, 1);
    return items;
  };

  const goTo = useCallback(async (enumValue: string) => {
    switch (enumValue) {
      case MenuItems.PACKAGE:
        window.location.href = `${REACT_APP_ZIQY}?page=ACCOUNT&member_id=${memberId}`;
        break;
      case MenuItems.FAQ:
        clickEvent(MATOMO_CATEGORY.ACCOUNT.FAQ);
        navigateTo(ROUTES.FAQ);
        break;
      case MenuItems.DECATHLON_ACCOUNT:
        clickEvent(MATOMO_CATEGORY.ACCOUNT.DECATHLON_ACCOUNT);
        window.open(REACT_APP_DECATHLON_ACCOUNT, '_blank');
        break;
      case MenuItems.LOGOUT:
        clickEvent(MATOMO_CATEGORY.ACCOUNT.LOGOUT);
        await logOut();
        break;
      case MenuItems.ENGAGEMENT:
        clickEvent(MATOMO_CATEGORY.ACCOUNT.ENGAGEMENT);
        navigateTo(ROUTES.ENGAGEMENT);
        break;
      default:
        console.warn('A implémenter');
    }
  }, [logOut, navigateTo, clickEvent, memberId]);

  return (
    <div>
      {/* { isActive !== RightsState.ACTIVE && (
        <div>
          <WPCButton
            variant="primary"
            size="stretched"
            onAction={() => navigateTo(ROUTES.SUBSCRIPTION_SELECTION)}
          >
            { t(`actions.${inactiveUserWpc ? 'continue_subscription' : 'discover_subscription'}`) }
          </WPCButton>
          <Spacer vertical={2} />
        </div>
      )} */}
      <div className={classes.root}>
        { getMenuItems().map((item, index) => (
          <button
            key={index}
            className={classes.item}
            onClick={() => goTo(item.enum)}
          >
            <div>
              <ItemLineAccount
                firstIcon={item.firstIcon}
                text={item.text}
                firstImg={item.firstImg}
                secondIcon={item.secondIcon}
              />
              {!item.isLast && <Divider />}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default MobileAccount;
