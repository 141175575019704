import type { FC } from 'react';
import classNames from 'classnames';
import type { ReviewInformation } from '@/types/Reviews';
import classes from './styles.module.scss';
import ReviewDetail from './ReviewDetail';
import { Rating } from '@/components';

type ReviewCellProps = {
  review: ReviewInformation;
};

const ReviewCell: FC<ReviewCellProps> = ({ review }) => (
  <div className={classes.review}>
    <Rating
      rating={review.rating}
      readonly
      size="small"
      showValue
    />
    <div>
      { review.title }
    </div>
    <div className={classNames('vtmn-typo_text-3', classes.use_since)}>
      { review.useSince }
    </div>
    <div className={classNames('vtmn-typo_text-3', classes.description_detail)}>
      <div className={classes.description}>
        <div>{ review.message }</div>
        <div className={classes.username}>
          { review.username } | { review.createdAt }
        </div>
      </div>
      <ReviewDetail review={review} />
    </div>
  </div>
);

export default ReviewCell;
