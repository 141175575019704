import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { PromotionalPriceAndPrice } from '@/components';
import type { LockerItem } from '@/types/Locker';

type Props = {
  isOpen: boolean;
  data: { [key: string]: LockerItem[] };
  openProduct: (stuff: LockerItem) => void;
  date: string;
};

export const Content: FC<Props> = ({
  isOpen,
  data,
  openProduct,
  date,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(styles.content, { [styles.show]: isOpen })}>
      {data[date]?.map((lockerItem) => (
        <button onClick={() => openProduct(lockerItem)} key={lockerItem.uuid} className={styles.product}>
          <div className={styles.leftSide}>
            <img src={lockerItem.cover} width={48} height={48} alt="" />
            <div>
              <div className="vtmn-font-bold">{lockerItem.brandLabel}</div>
              <div className={classnames(styles.label, 'vtmn-typo_text-3')}>{lockerItem.webLabel}</div>
              <div className="vtmn-typo_caption-1 vtmn-text-content-secondary">
                { t('locker.size', { size: lockerItem.size })}
              </div>
            </div>
          </div>
          <div className={styles.rightSide}>
            <PromotionalPriceAndPrice displayLabel={false} realPrice={lockerItem.price} />
          </div>
        </button>
      ))}
    </div>
  );
};
