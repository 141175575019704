import type { FC } from 'react';
import classes from './styles.module.scss';
import PackShot from '@/pages/Product/ProductAndSaleInfo/PackShot';
import ProductInformations from './ProductInformations';
import SaleInformations from './SaleInformations';
import type { Model as ModelType } from '@/types/Model';
import type { Bundle } from '@/types/Bundle';
import type { CreateUserSelection } from '@/types/Locker';

type ProductAndSaleInfoProps = {
  model: ModelType,
  setPropertyGenericInfo: (property: string) => void,
  getModel: (model_code: string) => void,
  isDesktop: boolean,
  onClickAddSelection: (infos: CreateUserSelection | CreateUserSelection[], model: ModelType[]) => void,
  bundle?: Bundle,
};

const ProductAndSaleInfo: FC<ProductAndSaleInfoProps> = ({
  model, setPropertyGenericInfo, isDesktop, getModel, onClickAddSelection, bundle,
}) => (
  <div className={classes.model}>
    <div className={classes.left_information}>
      {model.packShot.length > 0
          && <PackShot shots={model.packShot} />}
      { isDesktop
          && <ProductInformations onClickAddSelection={onClickAddSelection} bundle={bundle} model={model} setPropertyGenericInfo={setPropertyGenericInfo} />}
    </div>
    <div className={classes.right_information}>
      <SaleInformations
        model={model}
        setPropertyGenericInfo={setPropertyGenericInfo}
        onClickAddSelection={onClickAddSelection}
        onUpdateColor={getModel}
      />
    </div>
    { !isDesktop
        && <ProductInformations onClickAddSelection={onClickAddSelection} bundle={bundle} model={model} setPropertyGenericInfo={setPropertyGenericInfo} />}
  </div>
);

export default ProductAndSaleInfo;
