import type { FC } from 'react';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { Model as ModelType, SimpleModel } from '@/types/Model';
import classes from './styles.module.scss';
import GetModelUseCase from '@/usecases/GetModelUseCase';
import ProductAndSaleInfo from './ProductAndSaleInfo';
import ProductGenericInfo from './ProductGenericInfo';
import { useResponsive } from '@/hooks/useResponsive';
import CreateUserSelectionUseCase from '@/usecases/CreateUserSelectionUseCase';
import InfosAfterAddInSelection from './InfosAfterAddInSelection';
import { LockerContext } from '@/context/Locker/LockerProvider';
import GetUserGaugeUseCase from '@/usecases/GetUserGaugeUseCase';
import { ErrorManagementContext } from '@/context/ErrorManagement/ErrorManagementProvider';
import GoogleAnalytics from '@/service/GA/GA';
import { GaEvents } from '@/enum/GA';
import { GA_LABELS } from '@/types/GA';
import GetBundleByModelCodeUseCase from '@/usecases/GetBundleByModelCodeUseCase';
import type { Bundle } from '@/types/Bundle';
import type { CreateUserSelection } from '@/types/Locker';
import { Loader } from '@/components';

const Product: FC = () => {
  const { model_code: modelCode, super_model_code: superModelCode } = useParams();
  const { isMobile } = useResponsive();
  const { t, i18n } = useTranslation();

  const { updateNbItemsInLocker } = useContext(LockerContext);
  const { setIsError } = useContext(ErrorManagementContext);

  const [model, setModel] = useState<ModelType>();
  const [loader, setLoader] = useState<boolean>(true);
  const [propertyGenericInfo, setPropertyGenericInfo] = useState<string | null>(null);
  const [isErrorInAddSelection, setIsErrorInAddSelection] = useState<boolean>(false);
  const [simpleModel, setSimpleModel] = useState<SimpleModel[]>();
  const [isMaxSubscription, setIsMaxSubscription] = useState<boolean>(false);
  const [openInfoIsAddToSelection, setOpenInfoIsAddToSelection] = useState<boolean>(false);
  const [gaugeValue, setGaugeValue] = useState<number>(0);
  const [remainingSize, setRemainingSize] = useState(0);
  const [bundle, setBundle] = useState<Bundle | undefined>();

  const getModel = useCallback(async (modelCode: string, superModelCode: string) => {
    try {
      setLoader(true);
      const model = await GetModelUseCase.execute({
        model_code: modelCode,
        super_model_code: superModelCode,
        t,
      });
      if (model) setModel(model);
    } catch {
      setIsError(true);
    } finally {
      setLoader(false);
    }
  }, [setIsError, t]);

  const getBundle = useCallback(async (modelCode: string) => {
    const bundle = await GetBundleByModelCodeUseCase.execute(modelCode);

    if (bundle) {
      setBundle(bundle);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (!modelCode) return;
      await getBundle(modelCode);
    })();
  }, [modelCode, i18n.language]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (!modelCode || !superModelCode) return;
      await getModel(modelCode, superModelCode);
    })();
  }, [modelCode, i18n.language, superModelCode]); // eslint-disable-line

  const changeModel = async (modelCode: string) => {
    if (!superModelCode) return;
    await getModel(modelCode, superModelCode);
  };

  useEffect(() => {
    if (!model) return;
    GoogleAnalytics.hit(GaEvents.VIEW, {
      label: GA_LABELS.PRODUCT_DETAIL.VIEW_ITEM,
      model_id: model.modelCode,
      item_name: model.label,
      price: model.currentPrice,
      currency: '€',
    });
  }, [model]);

  const updateSelection = useCallback(async (infos: CreateUserSelection | CreateUserSelection[], model: ModelType[]) => {
    if (!model) return;
    try {
      setIsMaxSubscription(false);
      setIsErrorInAddSelection(false);
      setLoader(true);
      await CreateUserSelectionUseCase.execute(infos);
      updateNbItemsInLocker();
      setOpenInfoIsAddToSelection(true);
    } catch (e: any) {
      if (e.response.status === 400) {
        setIsErrorInAddSelection(true);
        setOpenInfoIsAddToSelection(true);
      } else setIsError(true);
    } finally {
      const user = await GetUserGaugeUseCase.execute();
      setRemainingSize(user.garageMaxSize - user.garageCurrentSize);
      setSimpleModel(model.map((model) => ({
        label: model.label,
        color_label: model.modelColor?.label,
        img_url: (model.packShot && model.packShot[0]?.url),
        price: (model.promotionalPrice || model.currentPrice),
        brand: model.brand,
      })));
      setGaugeValue((user.garageCurrentSize * 100) / user.garageMaxSize);
      if (isErrorInAddSelection && user.subscriptionType === 'BIG') setIsMaxSubscription(true);
      setLoader(false);
    }
  }, [model]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classNames(classes.root, { [classes.background_loader]: loader })}>
      {loader && (
        <div className={classes.loader}>
          <Loader size="large" />
        </div>
      )}
      { model
        && (
        <div>
          { !propertyGenericInfo
            ? (
              <ProductAndSaleInfo
                model={model}
                setPropertyGenericInfo={setPropertyGenericInfo}
                onClickAddSelection={updateSelection}
                getModel={changeModel}
                isDesktop={!isMobile}
                bundle={bundle}
              />
            )
            : <ProductGenericInfo model={model} setPropertyGenericInfo={setPropertyGenericInfo} propertyGenericInfo={propertyGenericInfo} />}
          { openInfoIsAddToSelection && simpleModel
            && (
            <InfosAfterAddInSelection
              isError={isErrorInAddSelection}
              gaugeValue={gaugeValue}
              model={simpleModel}
              displaySecondButton={!isMaxSubscription}
              updateIsOpen={(isOpen) => setOpenInfoIsAddToSelection(isOpen)}
              remainingSize={remainingSize}
            />
            )}
        </div>
        )}
    </div>
  );
};

export default Product;
