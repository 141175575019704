import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ReviewCell from './ReviewCell';
import type { GlobalReviewWithList } from '@/types/Reviews';
import classes from './styles.module.scss';
import Locale from '@/core/api/Locale';
import { WPCButton, Rating } from '@/components';

type ReviewListProps = {
  review: GlobalReviewWithList,
  modelCode: string,
};

const MAX_REVIEWS = 10;

const ReviewList: FC<ReviewListProps> = ({ review, modelCode }) => {
  const { t } = useTranslation();
  const lng = Locale.getCountry();

  return (
    <div className={classes.root}>
      <div className={classes.global_review}>
        <div className="vtmn-font-bold">
          <span className="vtmn-typo_title-2">{review.globalRating}</span>
          <span>/5</span>
        </div>
        <Rating
          rating={review.globalRating}
          readonly
          className={classes.rating_stars}
          size="small"
        />
        <div className={classNames('vtmn-typo_text-3', classes.nb_reviews)}>
          {t(`review.with_review.${review.nbReviews > 1 ? 'nb_review_multi' : 'nb_review'}`, { nb: review.nbReviews })}
        </div>
      </div>
      { review.reviews?.slice(0, MAX_REVIEWS).map((r, index) => (
        <ReviewCell review={r} key={index} />
      ))}
      { review.reviews?.length > 10
        ? (
          <div>
            <Link target="_blank" to={`${REACT_APP_DECATHLON}/${lng.languages}/search?Ntt=${modelCode}`}>
              <WPCButton
                variant="primary-reversed"
                className={classes.button}
                iconLeft="external-link-line"
                onAction={() => null}
              >
                { t('review.with_review.more_review')}
              </WPCButton>
            </Link>
          </div>
        )
        : null}
    </div>
  );
};

export default ReviewList;
