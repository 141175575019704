import type { FC, MouseEvent } from 'react';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTracking } from '@/hooks/useTracking';
import classes from '../styles.module.scss';
import MenuAccount from './MenuAccount';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import Assets from '@/core/api/Assets';

const AccountDesktopSelectedIcon = Assets.getAssets('/navigation/account_desktop_selected.svg');
const AccountDesktopIcon = Assets.getAssets('/navigation/account_desktop.svg');

const AccountNavigation: FC = () => {
  const { t } = useTranslation();
  const { clickEvent } = useTracking();

  const [openAccountMenu, setOpenAccountMenu] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openOrCloseMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    if (openAccountMenu) setAnchorEl(null);
    else {
      setAnchorEl(event.currentTarget);
      clickEvent(MATOMO_CATEGORY.HEADER.ACCOUNT);
    }
    setOpenAccountMenu(!openAccountMenu);
  }, [openAccountMenu, clickEvent]);

  return (
    <button onClick={openOrCloseMenu} className={classes.icon_text}>
      <div className={classes.icon_content}>
        <img
          alt="icon"
          src={openAccountMenu ? AccountDesktopSelectedIcon : AccountDesktopIcon}
        />
      </div>
      <span className={classNames(classes.text, 'vtmn-typo_caption-1', {
        [classes.selected]: openAccountMenu, 'vtmn-font-bold': openAccountMenu,
      })}
      >
        { t('navigation.account') }
      </span>
      { openAccountMenu
        && <MenuAccount open={openAccountMenu} anchorEl={anchorEl} closeMenu={openOrCloseMenu} />}
    </button>
  );
};

export default AccountNavigation;
