import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import type { FC } from 'react';
import classes from './styles.module.scss';
import { Spacer, WPCButton } from '@/components';
import type { Sub } from '@/types/Config';

type SubscriptionProps = {
  sub: Sub;
  onSelect: () => void;
  isSelected?: boolean;
  isSelectable?: boolean;
  showButton?: boolean;
};

const Subscription: FC<SubscriptionProps> = ({
  sub,
  onSelect,
  isSelected = false,
  isSelectable = false,
  showButton = true,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classnames(classes.root, isSelected && classes.selected)}>
      <div
        className={classnames(
          classes.vertical_bar,
          isSelected ? classes.selected : classes[sub.type],
        )}
      />
      <div className={classes.content}>
        <span className="vtmn-typo_text-2 vtmn-font-bold">
          {t('landing.subscriptions.sub.title', { name: t(sub.name) })}
        </span>
        <Spacer vertical={1} />
        <span className="vtmn-typo_title-2">
          {t('landing.subscriptions.sub.price', { price: sub.price })}
        </span>
        <Spacer vertical={2} />
        <span className="vtmn-typo_text-3 vtmn-text-content-secondary">
          { t('landing.subscriptions.sub.desc')}
          <span className="vtmn-font-bold">
            { t('landing.subscriptions.sub.desc2', { limit: sub.limit })}
          </span>
        </span>
        <Spacer vertical={1} />
        {showButton && (
          isSelected ? (
            <WPCButton
              onAction={onSelect}
              variant="tertiary"
              disabled={!isSelectable}
              size="stretched"
              iconLeft="checkbox-circle-line"
            >
              {t(`onboarding.subscription.${isSelectable ? 'selected' : 'coming_soon'}`)}
            </WPCButton>
          ) : (
            <WPCButton
              onAction={onSelect}
              disabled={!isSelectable}
              variant="primary-reversed"
              color={`blue_${sub.type}`}
              size="stretched"
            >
              {t(isSelectable ? 'landing.subscriptions.sub.select' : 'onboarding.subscription.coming_soon')}
            </WPCButton>
          )
        )}
      </div>
    </div>
  );
};

export default Subscription;
