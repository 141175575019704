import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Price } from '@/components';
import currencyFormatter from '@/utils/currencyFormatter';
import classes from './styles.module.scss';

type ProductSummaryProps = {
  brand: string;
  name: string;
  price?: number;
  packshot: string;
  withBorder?: boolean;
  size?: string,
};

const ProductSummary: FC<ProductSummaryProps> = ({
  brand,
  name,
  price,
  packshot,
  withBorder = false,
  size,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(classes.root, { [classes.with_border]: withBorder })}
    >
      <div className={classes.content_with_packshot}>
        <img src={packshot} className={classes.packshot} alt="packshot" />
        <div className={classes.content}>
          <span className="vtmn-typo_text-3 vtmn-font-bold">{brand}</span>
          <span className={classNames(classes.label, 'vtmn-typo_caption-1')}>{name}</span>
          <div className="vtmn-typo_caption-1 vtmn-text-content-secondary">
            { t('locker.size', { size })}
          </div>
        </div>
      </div>
      {price ? (
        <Price variant="accent" className={classes.price}>
          {currencyFormatter(price)}
        </Price>
      ) : null}
    </div>
  );
};

export default ProductSummary;
