import type React from 'react';
import classes from './styles.module.scss';
import { RadioButton } from '@/components';
import type { RadioValue } from '@/types/Locker';

type RadioButtonsProps = {
  title: string;
  tabs: RadioValue[];
  current?: RadioValue;
  background?: 'default' | 'white';
  onSelect: (value: RadioValue) => void;
};

const RadioButtons: React.FC<RadioButtonsProps> = ({
  title,
  tabs,
  current,
  background = 'default',
  onSelect,
}) => (
  <div className={classes.root}>
    <span className="vtmn-typo_text-1 vtmn-font-bold">{title}</span>
    <div className={classes.counts}>
      {tabs.map((x) => (
        <RadioButton
          key={x.value.toString()}
          text={x.value}
          selected={x.value === current?.value}
          onSelect={() => onSelect(x)}
          iconLeft={x.iconLeft}
          background={background}
        />
      ))}
    </div>
  </div>
);

export default RadioButtons;
