import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import { Spacer, TextInput } from '@/components';
import RadioButtons from '../RadioButtons';
import {
  SATISFACTION_VALUES,
  TIME_VALUES,
  USAGE_VALUES,
} from '@/types/Locker';
import type { RadioValue } from '@/types/Locker';

type Page1Props = {
  satisfaction: RadioValue | undefined;
  usage: RadioValue | undefined;
  time: RadioValue | undefined;
  setSatisfaction: (value: RadioValue) => void;
  setUsage: (value: RadioValue) => void;
  setTime: (value: RadioValue) => void;
};

const Page1: FC<Page1Props> = ({
  satisfaction,
  usage,
  time,
  setUsage,
  setTime,
  setSatisfaction,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.card}>
        <RadioButtons
          title={t('return.statisfaction.title')}
          tabs={SATISFACTION_VALUES}
          current={satisfaction}
          onSelect={(tab: RadioValue) => setSatisfaction(tab)}
        />
        <Spacer vertical={3} />
        <TextInput
          identifier="satifs_1"
          error={false}
          placeholder={t('return.statisfaction.placeholder')}
          labelText={t('return.statisfaction.help_us')}
          multiline
          disabled={satisfaction === undefined}
          valid={false}
        />
      </div>
      <Spacer vertical={3} />
      <div className={classes.card}>
        <RadioButtons
          title={t('return.usage.title')}
          tabs={USAGE_VALUES}
          current={usage}
          onSelect={(tab: RadioValue) => setUsage(tab)}
        />
      </div>
      <Spacer vertical={3} />
      <div className={classes.card}>
        <RadioButtons
          title={t('return.time.title')}
          tabs={TIME_VALUES}
          current={time}
          onSelect={(tab: RadioValue) => setTime(tab)}
        />
      </div>
    </div>
  );
};

export default Page1;
