import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PopUp from 'src/components/vtmn/PopUp';
import classes from './styles.module.scss';
import {
  Spacer, WPCButton, Icon, ModalActions,
} from '@/components';
import { siniterNumber } from '@/constants/sinister';

type Props = {
  isOpen: boolean,
  onClose: () => void,
};

const PopUpSinister: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <PopUp
      onClose={onClose}
      isOpen={isOpen}
      title={t('locker.my_locker.pop_up_sinister.title')}
    >
      <div>
        <div className={classes.root}>
          <Spacer vertical={2} />
          <span className="vtmn-typo_text-2">
            { t('locker.my_locker.pop_up_sinister.description') }
          </span>
          <Spacer vertical={2} />
          <div className={classes.phone_call}>
            <Icon
              value="phone-line"
              className={classes.icon}
              size={16}
              variant="brand"
            />
            <Spacer horizontal={1} />
            <div className="vtmn-typo_title-5">
              { siniterNumber }
            </div>
          </div>
        </div>
        <Spacer vertical={2} />
        <ModalActions>
          <WPCButton
            variant="secondary"
            onAction={onClose}
          >
            {t('locker.my_locker.pop_up_sinister.actions.cancel')}
          </WPCButton>
          <a
            href={`tel:${siniterNumber}`}
            target="_selft"
          >
            <WPCButton
              variant="primary"
              onAction={() => {}}
              size="stretched"
            >
              {t('locker.my_locker.pop_up_sinister.actions.call')}
            </WPCButton>
          </a>
        </ModalActions>
      </div>
    </PopUp>
  );
};

export default PopUpSinister;
