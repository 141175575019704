import type { FC } from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { PopUpExcludedProducts } from '@/components';
import Condition from './Condition';
import classes from './styles.module.scss';

const Conditions: FC = () => {
  const { t } = useTranslation();

  const [isOpenPopUp, setIsOpenPopUp] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <p className="vtmn-typo_text-1 vtmn-font-bold">{t('terms.title')}</p>
      <div>
        <Condition>
          <div>
            <Trans i18nKey="terms.all_products.1" />
            <button
              onClick={() => setIsOpenPopUp(true)}
              className={classNames(classes.link, 'vtmn-text-content-action vtmn-font-bold')}
            >
              { t('terms.all_products.2') }
            </button>
            { t('terms.all_products.3') }
          </div>
        </Condition>
        <Condition>
          <Trans i18nKey="terms.reconditionning" />
        </Condition>
        <Condition>
          <Trans i18nKey="terms.duration" />
        </Condition>
        <Condition>
          <Trans i18nKey="terms.bring_to_store" />
        </Condition>
        <Condition>
          <Trans i18nKey="terms.terminate" />
        </Condition>
      </div>
      <PopUpExcludedProducts onClose={() => setIsOpenPopUp(false)} isOpen={isOpenPopUp} />
    </div>
  );
};

export default Conditions;
