import type { FC } from 'react';
import { VtmnPrice } from '@vtmn/react';
import type { VtmnPriceVariant, VtmnPriceSize } from '@vtmn/react/dist/src/components/indicators/VtmnPrice/types';

type Props = {
  size?: VtmnPriceSize,
  variant: VtmnPriceVariant,
  className?: string,
  children: JSX.Element | string,
};

const Price: FC<Props> = ({
  variant, className, children, size,
}) => (
  <VtmnPrice variant={variant} className={className} size={size}>
    { children }
  </VtmnPrice>
);

export default Price;
