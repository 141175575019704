import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import type { Bundle as BundleType, BundleModel } from '@/types/Bundle';
import styles from './styles.module.scss';
import {
  PromotionalPriceAndPrice, Spacer, WPCButton, Icon,
} from '@/components';
import { useResponsive } from '@/hooks/useResponsive';
import type { Model, Model as ModelType, Size } from '@/types/Model';
import type { CreateUserSelection } from '@/types/Locker';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { useTracking } from '@/hooks/useTracking';
import SizeDrawer from '@/pages/Product/ProductAndSaleInfo/ProductInformations/Bundle/Drawer';

interface Props {
  bundle: BundleType,
  onClickAddSelection: (infos: CreateUserSelection | CreateUserSelection[], model: ModelType[]) => void,
}

type SizesSelected = {
  size: Size;
  modelCode: string,
};

export const Bundle: FC<Props> = ({ bundle, onClickAddSelection }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const { clickEvent } = useTracking();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [openSizeSelector, setOpenSizeSelector] = useState(false);
  const [selectedSize, setSelectedSize] = useState<SizesSelected[]>([]);
  const [modelNeedSize, setModelNeedSize] = useState<BundleModel[] | null>(null);

  useEffect(() => {
    const items = bundle.models.map(({ model }) => model.modelCode);
    setSelectedItems(items);
  }, [bundle.models]);

  const selectItem = (modelCode: string) => {
    if (selectedItems.indexOf(modelCode) > -1) {
      setSelectedItems(selectedItems.filter((item) => item !== modelCode));
      return;
    }
    setSelectedItems([
      ...selectedItems,
      modelCode,
    ]);
  };

  const buildBundle = () => {
    const div: any = [];
    bundle.models
      .sort((a, b) => Number(b.isModelInvest) - Number(a.isModelInvest))
      .forEach(({ model }, index) => {
        div.push(
          <div key={model.modelCode} className={styles.bundle}>
            <div className={styles.check}>
              <img className={styles.img} src={model.packShot[0]?.url} width={isMobile ? 112 : 166} height={isMobile ? 112 : 166} alt="" />
              <input
                className="vtmn-checkbox"
                type="checkbox"
                id={model.modelCode}
                onChange={() => selectItem(model.modelCode)}
                checked={!!selectedItems.find((code) => code === model.modelCode)}
              />
              <label htmlFor={model.modelCode} />
            </div>
            <Spacer vertical={3} />
            <div className={styles.infos}>
              <div className="vtmn-font-bold">{model.brand}</div>
              <div className={classnames('vtmn-typo_caption-1', styles.trunc)}>{model.label}</div>
              <Spacer vertical={1} />
              <PromotionalPriceAndPrice realPrice={model.currentPrice!} displayLabel={false} />
            </div>
          </div>,
        );

        if (index < bundle.models.length - 1 && !isMobile) {
          div.push(<Icon
            size={24}
            value="add-line"
            variant="default"
          />);
        }
      });
    return div;
  };

  const getBundlePrice = bundle.models.filter(({ model }) => selectedItems.includes(model.modelCode)).reduce((acc, curr) => acc + curr.model.currentPrice!, 0);

  const selectedBundle = bundle.models.filter(({ model }) => selectedItems.includes(model.modelCode));
  const modelWithSize = selectedBundle.filter((b) => b.model.itemIds.length > 1);

  const addToSelection = useCallback(() => {
    if (modelWithSize.length && selectedSize.length !== modelWithSize.length) {
      setModelNeedSize(modelWithSize);
      setOpenSizeSelector(true);
      return;
    }

    const d = selectedBundle.map(({ model }) => {
      const indexSize = selectedSize.find((size) => size.modelCode === model.modelCode);
      return ({
        superModelCode: model.superModelCode,
        modelCode: model.modelCode,
        itemId: indexSize ? indexSize.size.itemId : model.itemIds[0],
        quantity: 1,
      });
    });

    onClickAddSelection(d, bundle.models.filter(({ model }) => selectedItems.includes(model.modelCode)).map((d) => d.model) as Model[]);
    clickEvent(MATOMO_CATEGORY.BUNDLE.ADD_KIT);
    const items = bundle.models.map(({ model }) => model.modelCode);
    setSelectedItems(items);
    setSelectedSize([]);
    setModelNeedSize(null);
    setOpenSizeSelector(false);
  }, [modelWithSize, selectedSize, selectedBundle, clickEvent, onClickAddSelection, bundle.models, selectedItems]);

  const closePopUpSize = () => {
    setSelectedSize([]);
    setModelNeedSize(null);
    setOpenSizeSelector(false);
  };

  const selectSizeItem = (size: Size, modelCode: string) => {
    const foundedSize = selectedSize.findIndex((sizes: SizesSelected) => sizes.modelCode === modelCode);
    if (foundedSize === -1) {
      setSelectedSize((prevState) => [
        { size, modelCode },
        ...prevState,
      ]);
      return;
    }

    const t = selectedSize.map((sizes) => {
      if (sizes.modelCode === modelCode) return { modelCode, size };
      return sizes;
    });
    setSelectedSize(t);
  };

  return (
    <div className={styles.container}>
      <div className="vtmn-font-bold vtmn-typo_title-4">{t('product.bundle.title')}</div>
      <div className="vtmn-typo_text-1">{t('product.bundle.subtitle')}</div>
      <Spacer vertical={1.5} />
      <div className={styles.white}>
        <div className={styles.bundles}>
          {buildBundle()}
        </div>
        <Spacer vertical={2.5} />
        <div className={styles.center}>
          <div className={classnames('vtmn-font-bold vtmn-typo_title-5', styles.price)}>{t('product.bundle.total')} {getBundlePrice}€</div>
          <WPCButton disabled={!selectedItems.length} iconLeft="add-line" size={isMobile ? 'stretched' : 'medium'} variant="conversion" onAction={addToSelection}>
            <span>{t('product.bundle.add')}</span>
          </WPCButton>
        </div>
      </div>
      {openSizeSelector && (
        <SizeDrawer
          addToSelection={addToSelection}
          disabled={!!modelWithSize.length && selectedSize.length !== modelWithSize.length}
          closePopUpSize={closePopUpSize}
          isMobile={isMobile}
          modelNeedSize={modelNeedSize}
          openSizeSelector={openSizeSelector}
          selectSizeItem={selectSizeItem}
        />
      )}
    </div>
  );
};
