import type { FC } from 'react';
import { useCallback, useState, useContext } from 'react';
import type { LockerItem } from '@/types/Locker';
import { Header } from '../../Header';
import { Content } from '../index';
import ValidateEquipmentUseCase from '@/usecases/ValidateEquipmentUseCase';
import { ErrorManagementContext } from '@/context/ErrorManagement/ErrorManagementProvider';

type LigneContentProps = {
  data: { [key: string]: LockerItem[] };
  date: string,
  icon: any,
  title: string,
  state: string,
  subtitle?: string,
  openProduct: (item: LockerItem) => void;
  reloadEquipment: () => void;
};

const LigneContent: FC<LigneContentProps> = ({
  state, icon, date, openProduct, title, data, reloadEquipment, subtitle,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { setIsError } = useContext(ErrorManagementContext);

  const updateStatus = useCallback(async () => {
    try {
      await ValidateEquipmentUseCase.execute({ items: data[date].map((i) => i.uuid), status: state });
      reloadEquipment();
    } catch {
      setIsError(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Header
        subtitle={subtitle}
        state={state}
        icon={icon}
        date={date}
        title={title}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        updateStatus={() => updateStatus()}
      />
      <Content isOpen={isOpen} data={data} openProduct={openProduct} date={date} />
    </div>
  );
};

export default LigneContent;
