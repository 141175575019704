import type { FC } from 'react';
import { VtmnListItemStartVisual } from '@vtmn/react';

type Props = {
  children: JSX.Element | string | JSX.Element[],
};

const ListItemStart: FC<Props> = ({ children }) => (
  <VtmnListItemStartVisual>
    { children }
  </VtmnListItemStartVisual>
);

export default ListItemStart;
