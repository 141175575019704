import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Container';
import { Icon } from '@/components';
import type { LockerItem } from '@/types/Locker';
import { LOCKER_STATE } from '@/constants/locker';

type Props = {
  toCollect: { [key: string]: LockerItem[] },
  reloadEquipment: () => void,
};

export const ToCollect: FC<Props> = ({ toCollect, reloadEquipment }) => {
  const { t } = useTranslation();

  return (
    <Container
      title={t('locker.state.to_collect')}
      icon={(
        <Icon
          size={24}
          value="check-line"
          variant="positive"
        />
)}
      subtitle={t('locker.state_subtitle.to_collect')}
      reloadEquipment={reloadEquipment}
      data={toCollect}
      state={LOCKER_STATE.TO_COLLECT}
    />
  );
};
