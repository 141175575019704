import type { FC } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import Community from '@/pages/Homepage/Community';
import {
  Spacer, CardsService, Welcome,
  NextStepCloture, DataExportCloture,
} from '@/components';
import { RightsState } from '@/context/Rights/RightsState';
import { RightsContext } from '@/context/Rights/RightsProvider';

const HomePage: FC = () => {
  const { t } = useTranslation();
  const { isActive } = useContext(RightsContext);

  return (
    <div className={classes.content}>
      <div className={classes.root}>
        <Welcome titleWelcome={t(`homepage.${isActive === RightsState.ACTIVE ? 'hook_after_6_may' : 'hook_inactive'}`)} />
        <Spacer vertical={6} />
        <Community />
        <Spacer vertical={6} />
        <NextStepCloture />
        <Spacer vertical={6} />
        <DataExportCloture />
        <Spacer vertical={6} />
        <CardsService />
      </div>
    </div>
  );
};

export default HomePage;
