import type { FC } from 'react';
import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { useTracking } from '@/hooks/useTracking';
import {
  Spacer, PopUp, WPCButton, ModalActions,
} from '@/components';
import LocaleStorage from '@/utils/LocalStorage';
import classes from './styles.module.scss';
import { AuthContext } from '@/context/Auth/AuthProvider';
import GetUserRightsUseCase from '@/usecases/GetUserRightsUseCase';
import { SubscriptionStatus } from '@/enum/subscriptionStatus';
import { ROUTES } from '@/pages/routes';
import { AuthRepository } from '@/repositories';
import { useResponsive } from '@/hooks/useResponsive';
import { MATOMO_CATEGORY } from '@/constants/matomo';

type Props = {
  openPopUp: boolean,
  closePopUp: () => void,
};

const PopUpSubscribe: FC<Props> = ({ openPopUp, closePopUp }) => {
  const { t } = useTranslation();
  const { isAuth } = useContext(AuthContext);
  const navigateTo = useNavigate();
  const { isMobile } = useResponsive();
  const { clickEvent } = useTracking();

  const [inactiveUserWpc, setInactiveUserWpc] = useState<boolean>(false);

  const getLink = (): string => {
    const lng = LocaleStorage.getLng();
    return lng === 'fr' ? 'https://docs.google.com/forms/d/e/1FAIpQLSdpPCOGohe6Luo4q4wsCZcbTiPY-i4Tt1Q4rmlg2J31VXMPnw/viewform?usp=sf_link'
      : 'https://docs.google.com/forms/d/e/1FAIpQLSd6CSxo8boxVFb2KXVAZ3LJDxAmgV4jxP8CAt39sVCfkQxJ9A/viewform?usp=sf_link';
  };

  const handleConnect = () => {
    closePopUp();
    AuthRepository.signinRedirect();
  };

  const goToSubscriptionSelection = () => {
    if (!inactiveUserWpc) {
      clickEvent(MATOMO_CATEGORY.PRODUCT_PAGE.DISCOVER_SUBSCRIBE);
    }
    closePopUp();
    navigateTo(ROUTES.SUBSCRIPTION_SELECTION);
  };

  useEffect(() => {
    (async () => {
      const rights = await GetUserRightsUseCase.execute();
      if (rights === SubscriptionStatus.INACTIVE) setInactiveUserWpc(true);
    })();
  }, []);

  return (
    <PopUp
      isOpen={openPopUp}
      onClose={() => closePopUp()}
      title={t('pop_up_subscribe.title')}
    >
      <div>
        <div className={classes.container}>
          <Spacer vertical={1} />
          <Trans i18nKey="pop_up_subscribe.subtitle" />
          <Link
            target="_blank"
            to={getLink()}
            className={classNames('vtmn-font-bold', classes.link_ask)}
          >
            { t('pop_up_subscribe.ask') }
          </Link>
          <Spacer vertical={2} />
        </div>
        <ModalActions>
          <> { !isAuth && !isMobile
            && (
              <WPCButton
                onAction={() => handleConnect()}
                variant="secondary"
              >
                { t('pop_up_subscribe.already_subscriber') }
              </WPCButton>
            )}
          </>
          <WPCButton
            variant="primary"
            onAction={() => goToSubscriptionSelection()}
          >
            {t(`pop_up_subscribe.${inactiveUserWpc ? 'continue_subscription' : 'discover'}`)}
          </WPCButton>
          <> { !isAuth && isMobile
            && (
              <WPCButton
                onAction={() => handleConnect()}
                variant="secondary"
              >
                { t('pop_up_subscribe.already_subscriber') }
              </WPCButton>
            )}
          </>
        </ModalActions>
      </div>
    </PopUp>
  );
};

export default PopUpSubscribe;
