import type { FC } from 'react';
import classNames from 'classnames';
import type { LockerItem } from '@/types/Locker';
import classes from './styles.module.scss';
import { PromotionalPriceAndPrice, Spacer } from '@/components';

type Props = {
  item: LockerItem;
};

const ProductSummary: FC<Props> = ({ item }) => (
  <div className={classes.root}>
    <div className={classes.image_model}>
      <img src={item.cover} alt="img_model" />
    </div>
    <div className={classes.label_price}>
      <div className={classes.label}>
        <div className="vtmn-typo_text-3 vtmn-font-bold"> { item.brandLabel } </div>
        <Spacer vertical={0.5} />
        <div className={classNames('vtmn-typo_caption-1', classes.web_label)}>
          { item.webLabel }
        </div>
        <div className="vtmn-typo_caption-1 vtmn-text-content-tertiary"> { item.size } </div>
      </div>
      <PromotionalPriceAndPrice realPrice={item.price} displayLabel={false} />
    </div>
  </div>
);

export default ProductSummary;
