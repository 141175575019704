import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { GlobalReviewWithList } from '@/types/Reviews';
import NoReview from './NoReview';
import WithReview from './ReviewList';
import GetReviewsForModelUseCase from '@/usecases/GetReviewsForModelUseCase';

type ReviewsProps = {
  modelCode: string,
  superModelCode: string,
};

const Review: FC<ReviewsProps> = ({ modelCode, superModelCode }) => {
  const [reviews, setReviews] = useState<GlobalReviewWithList>();

  useEffect(() => {
    (async () => {
      const reviewsFromBack = await GetReviewsForModelUseCase.execute({
        modelCode, superModelCode,
      });
      setReviews(reviewsFromBack);
    })();
  }, [modelCode, superModelCode]);

  return (
    <div>
      { reviews?.reviews
        ? <WithReview review={reviews} modelCode={modelCode} />
        : <NoReview />}
    </div>
  );
};

export default Review;
