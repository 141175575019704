import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Container';
import type { LockerItem } from '@/types/Locker';
import { LOCKER_STATE } from '@/constants/locker';
import { Icon } from '@/components';

type Props = {
  confirmed: { [key: string]: LockerItem[] },
  reloadEquipment: () => void;
};

export const Confirmed: FC<Props> = ({ confirmed, reloadEquipment }) => {
  const { t } = useTranslation();

  return (
    <Container
      title={t('locker.state.confirmed')}
      icon={(
        <Icon
          size={24}
          value="bill-line"
          variant="positive"
        />
)}
      subtitle={t('locker.state_subtitle.confirmed_in_preparation')}
      reloadEquipment={reloadEquipment}
      data={confirmed}
      state={LOCKER_STATE.VALIDATED}
    />
  );
};
