import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Spacer, ModalVtmn } from '@/components';
import classes from './styles.module.scss';
import { useResponsive } from '@/hooks/useResponsive';

type Props = {
  onClose: () => void,
  isOpen: boolean,
};

const PopUpExcludedProducts: FC<Props> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const indexProducts = [1, 2, 3, 4, 5, 6, 7];

  return (
    <ModalVtmn open={isOpen} onClose={onClose} className={classes.root} title={t('product_excluded.title')}>
      <Spacer vertical={3} />
      <ul>
        { indexProducts.map((index) => (
          <li key={index}>
            <span className="vtmn-typo_text-1 vtmn-font-bold">
              { t(`product_excluded.${index}.title`) }
            </span>
            {isMobile && <br />}
            <span className="vtmn-typo_text-2 vtmn-text-content-tertiary">
              { t(`product_excluded.${index}.description`) }
            </span>
          </li>
        ))}
      </ul>
    </ModalVtmn>
  );
};

export default PopUpExcludedProducts;
