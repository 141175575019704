import type { FC } from 'react';
import { VtmnRating } from '@vtmn/react';
import type { VtmnRatingSize } from '@vtmn/react/dist/src/components/indicators/VtmnRating/types';

type Props = {
  readonly: boolean,
  rating?: number,
  className?: string,
  size: VtmnRatingSize,
  showValue?: boolean,
};

const Rating: FC<Props> = ({
  readonly, rating, className, size, showValue,
}) => (
  <VtmnRating
    value={rating}
    readonly={readonly}
    className={className}
    size={size}
    showValue={showValue}
  />
);

export default Rating;
