import type { FC } from 'react';
import {
  VtmnList,
} from '@vtmn/react';

type Props = {
  children: JSX.Element | string | JSX.Element[],
  divider: boolean,
  className: string,
};

const List: FC<Props> = ({ children, divider, className }) => (
  <VtmnList divider={divider} className={className}>
    { children }
  </VtmnList>
);

export default List;
