import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import classNames from 'classnames';
import { Icon } from '@/components';
import classes from './styles.module.scss';

type Props = {
  firstIcon?: VitamixId,
  firstImg?: string,
  text: string,
  secondIcon?: VitamixId,
};

const ItemLine: FC<Props> = ({
  firstIcon,
  text,
  secondIcon,
  firstImg,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.item}>
      <div className={classes.icon_text}>
        <div className={classes.first_icon_text}>
          <div className={classes.icon}>
            {firstIcon && <Icon value={firstIcon} size={24} />}
            {firstImg && <img src={firstImg} alt="icon" />}
          </div>
          <span className={classNames(classes.text, 'vtmn-typo_text-2 vtmn-font-bold')}>
            {t(text)}
          </span>
        </div>
        { secondIcon && <Icon value={secondIcon} size={20} />}
      </div>
    </div>
  );
};

export default ItemLine;
