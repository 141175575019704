import type { FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import type { Model, Size } from '@/types/Model';
import {
  Drawer, Spacer, WPCButton, Icon, SizeSelector,
} from '@/components';
import styles from './styles.module.scss';
import type { BundleModel } from '@/types/Bundle';
import ModelInfo from '@/pages/Product/InfosAfterAddInSelection/ModelInfo';

type Props = {
  openSizeSelector: boolean,
  closePopUpSize: () => void,
  isMobile: boolean,
  modelNeedSize: BundleModel[] | null,
  disabled: boolean,
  selectSizeItem: (size: Size, modelCode: string) => void,
  addToSelection: () => void,
};

const SizeDrawer: FC<Props> = ({
  openSizeSelector,
  closePopUpSize,
  isMobile,
  modelNeedSize,
  disabled,
  selectSizeItem,
  addToSelection,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      isOpen={openSizeSelector}
      updateDrawer={closePopUpSize}
      borderTopRightRadius={isMobile ? 16 : 0}
      borderTopLeftRadius={isMobile ? 16 : 0}
      anchor={isMobile ? 'bottom' : 'right'}
    >
      <div className={styles.drawer_root}>
        <div className={styles.icons_title}>
          <div className={classnames('vtmn-typo_title-4', styles.icon_title)}>
            {t('product.bundle.select_size')}
          </div>
          <Icon
            value="close-line"
            variant="default"
            onClick={closePopUpSize}
            size={24}
            className={styles.button_close}
          />
        </div>
        {modelNeedSize?.length && (
          modelNeedSize.map((model) => (
            <div key={model.model.modelCode}>
              <ModelInfo
                key={model.model.modelCode}
                model={[model?.model as Model].map((model) => ({
                  label: model.label,
                  color_label: model.modelColor?.label,
                  img_url: (model.packShot && model.packShot[0]?.url),
                  price: (model.promotionalPrice || model.currentPrice),
                  brand: model.brand,
                }))}
              />
              <Spacer vertical={2} />
              <SizeSelector
                showTitle={false}
                sizes={model?.model.size!}
                onUpdateSize={(size) => selectSizeItem(size, model.model.modelCode)}
                initialSizeSelected={null}
              />
              <Spacer vertical={4} />
              <Divider />
              <Spacer vertical={1} />
            </div>
          ))
        )}
        <WPCButton
          disabled={disabled}
          size="stretched"
          variant="primary"
          onAction={addToSelection}
        >
          <span>{t('product.bundle.confirm')}</span>
        </WPCButton>
      </div>
    </Drawer>
  );
};

export default SizeDrawer;
