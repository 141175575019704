import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResponsive } from '@/hooks/useResponsive';
import classes from './styles.module.scss';
import type { SubscriptionChoice } from './Subscription';
import Subscription from './Subscription';
import { BackButton, Spacer } from '@/components';
import { ROUTES } from '@/pages/routes';
import LocalStorage from '@/utils/LocalStorage';
import { AuthRepository, UserRepository } from '@/repositories';
import { AuthContext } from '@/context/Auth/AuthProvider';
import type { LandingContent } from '@/types/Config';
import GetLandingUseCase from '@/usecases/GetLandingUseCase';
import { AuthState } from '@/context/Auth/AuthState';
import { RightsContext } from '@/context/Rights/RightsProvider';
import GetUserStoreAndSubscription from '@/usecases/GetUserStoreAndSubscriptionUseCase';
import { RightsState } from '@/context/Rights/RightsState';

const SubscriptionSelection: FC = () => {
  const { isMobile } = useResponsive();
  const { t, i18n } = useTranslation();
  const navigateTo = useNavigate();
  const location = useLocation();
  const { isAuth } = useContext(AuthContext);
  const { isActive } = useContext(RightsContext);

  const [selectedStore, setSelectedStore] = useState<string>('');
  const [selectedSub, setSelectedSub] = useState<number>(-1);
  const [content, setContent] = useState<LandingContent>();

  useEffect(() => {
    if (AuthState.CONNECTED && isActive === RightsState.ACTIVE) navigateTo(ROUTES.HOME_PAGE);
  }, [isActive, navigateTo]);

  useEffect(() => {
    if (isAuth === AuthState.CONNECTED && LocalStorage.isOnboarding()) {
      void createUser();
    } else if (isAuth !== AuthState.CONNECTED && location.state
          && !(location.state.selectedSub === -1)) {
      setSelectedSub(location.state.selectedSub);
    } else if (isAuth === AuthState.CONNECTED && isActive === RightsState.ACTIVE) {
      navigateTo(ROUTES.HOME_PAGE);
    }
  }, [isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      const user = await GetUserStoreAndSubscription.execute();
      if (user) {
        setSelectedStore(user.store);
        switch (user.subscription_type) {
          case 'BIG':
            setSelectedSub(2);
            break;
          case 'MEDIUM':
            setSelectedSub(1);
            break;
          default:
            setSelectedSub(0);
            break;
        }
        if (location.state.selectedStore !== -1) setSelectedStore(location.state.selectedStore);
        if (location.state.selectedSub !== -1) setSelectedSub(location.state.selectedSub);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      const land = await GetLandingUseCase.execute();
      setContent(land);
    })();
  }, [i18n.language]);

  const createUser = async () => {
    const onBoarding = LocalStorage.getSelectedSubscription();
    if (onBoarding) {
      LocalStorage.clearSubscription();
      await UserRepository.createUser(onBoarding.store, onBoarding.subType);
      navigateTo(ROUTES.HOME_PAGE);
    }
  };

  const handleContinue = (choice: SubscriptionChoice) => {
    LocalStorage.setSelectedSubscription(choice);
    AuthRepository.signinRedirect();
  };

  return (
    <div className={classes.root}>
      {!isMobile && <Spacer vertical={6} />}
      <div className={classes.title}>
        <BackButton onBack={() => navigateTo(ROUTES.LANDING)} />
        <Spacer horizontal={3} vertical={2} />
        <p className="vtmn-typo_title-4">{t('onboarding.subscription.title')}</p>
      </div>
      <Spacer vertical={2} />
      {content ? (
        <Subscription
          selectedStore={selectedStore}
          selectedSub={selectedSub}
          onContinue={handleContinue}
          content={content}
          setSelectedStore={setSelectedStore}
        />
      ) : null}
    </div>
  );
};

export default SubscriptionSelection;
