import type { FC } from 'react';
import { SwipeableDrawer } from '@mui/material';
import { useResponsive } from '@/hooks/useResponsive';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

type DrawerProps = {
  children: JSX.Element | JSX.Element[],
  isOpen: boolean,
  updateDrawer: (openDrawer: boolean) => void,
  anchor?: Anchor,
  height?: string,
  width?: string,
  borderTopLeftRadius?: number,
  borderTopRightRadius?: number,
};

const Drawer : FC<DrawerProps> = ({
  children,
  isOpen,
  updateDrawer,
  anchor = 'right',
  height,
  width,
  borderTopRightRadius,
  borderTopLeftRadius,
}) => {
  const { isMobile } = useResponsive();

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          width: width || (anchor === 'right' || anchor === 'left' ? { xs: '70%', lg: '50%', xl: '40%' } : { xs: '100%' }),
          height: height || (isMobile ? { xs: '50%' } : { xs: '100%' }),
          borderTopLeftRadius,
          borderTopRightRadius,
        },
      }}
      anchor={anchor}
      open={isOpen}
      onOpen={() => updateDrawer(true)}
      onClose={() => updateDrawer(false)}
    >
      { children }
    </SwipeableDrawer>
  );
};

export default Drawer;
