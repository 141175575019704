import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import { GenericInformations, Review, Icon } from '@/components';
import type { Model as ModelType } from '@/types/Model';
import GoogleAnalytics from '@/service/GA/GA';
import { GaEvents } from '@/enum/GA';
import { GA_LABELS } from '@/types/GA';

type ProductGenericInfoProps = {
  model: ModelType,
  setPropertyGenericInfo: (property: string | null) => void,
  propertyGenericInfo: string,
};

const ProductGenericInfo: FC<ProductGenericInfoProps> = ({ propertyGenericInfo, setPropertyGenericInfo, model }) => {
  const { t } = useTranslation();

  const getElements = () => {
    switch (propertyGenericInfo) {
      case 'advantages':
        return model.advantages;
      case 'functionalities':
        return model.functionalities;
      default:
        return model.composition;
    }
  };

  useEffect(() => {
    if (propertyGenericInfo !== 'reviews') return;
    GoogleAnalytics.hit(GaEvents.CLICK, {
      label: GA_LABELS.PRODUCT_DETAIL.VIEW_REVIEW,
      model_id: model.modelCode,
      item_name: model.label,
    });
  }, [model, propertyGenericInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={classes.first_line}>
        <Icon
          value="arrow-left-fill"
          size={20}
          onClick={() => setPropertyGenericInfo(null)}
          className={classes.button_back}
        />
        <div className="vtmn-font-bold vtmn-typo_text-1">
          {t(`product.${propertyGenericInfo}`)}
        </div>
      </div>
      { propertyGenericInfo === 'reviews'
        ? <Review modelCode={model.modelCode} superModelCode={model.superModelCode} />
        : <GenericInformations informations={getElements()} />}
    </div>
  );
};

export default ProductGenericInfo;
