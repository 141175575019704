import type { FC } from 'react';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import classes from './styles.module.scss';
import SpotlightImage from './SpotlightImage';
import type { Shot } from '@/types/Packshot';
import Thumbnails from './Thumbnails';
import { useResponsive } from '@/hooks/useResponsive';

type PackShotProps = {
  shots: Shot[],
};

const PackShot: FC<PackShotProps> = ({ shots }) => {
  const { isMobile } = useResponsive();
  const [selectedImgIndex, setSelectedImgIndex] = useState<number>(0);

  const pagination = {
    clickable: true,
    renderBullet(index: number, className: string) {
      return `<span class="${className}">' </span>`;
    },
    dynamicBullets: true,
    dynamicMainBullets: 3,
    clickableClass: (classes.swipperDots),
  };

  return (
    <div>
      { !isMobile
        ? (
          <div className={classes.root}>
            <Thumbnails shots={shots} indexSelect={selectedImgIndex} onClick={setSelectedImgIndex} />
            <SpotlightImage url={shots[selectedImgIndex].url} />
          </div>
        )
        : (
          <div className={classes.slider}>
            <Swiper pagination={pagination} modules={[Pagination]}>
              {shots.map((shot, index) => (
                <SwiperSlide key={index} className={classes.slide}>
                  <img src={shot.url} alt="Img" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
    </div>
  );
};

export default PackShot;
