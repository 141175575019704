import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Review, Icon } from '@/components';
import classes from '../styles.module.scss';
import type { Model as ModelType } from '@/types/Model';
import { useResponsive } from '@/hooks/useResponsive';
import GoogleAnalytics from '@/service/GA/GA';
import { GaEvents } from '@/enum/GA';
import { GA_LABELS } from '@/types/GA';

type DrawerCardInfoProps = {
  model: ModelType,
  isOpen: boolean,
  updateSwipeableDrawer: (openDrawer: boolean) => void,
};

const DrawerReviews: FC<DrawerCardInfoProps> = ({ model, updateSwipeableDrawer, isOpen }) => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();

  useEffect(() => {
    GoogleAnalytics.hit(GaEvents.CLICK, {
      label: GA_LABELS.PRODUCT_DETAIL.VIEW_REVIEW,
      model_id: model.modelCode,
      item_name: model.label,
    });
  }, [model]);

  return (
    <Drawer
      isOpen={isOpen}
      updateDrawer={updateSwipeableDrawer}
      anchor="right"
      borderTopRightRadius={isMobile ? 16 : 0}
      borderTopLeftRadius={isMobile ? 16 : 0}
    >
      <div>
        { model.reviews
          && (
          <div className={classes.root_drawer}>
            <div className={classes.first_line}>
              <div className="vtmn-typo_title-4 vtmn-font-bold">
                {t('product.reviews')}
              </div>
              <Icon
                value="close-line"
                variant="default"
                onClick={() => updateSwipeableDrawer(false)}
                size={24}
                className={classes.button_close}
              />
            </div>
            <Review modelCode={model.modelCode} superModelCode={model.superModelCode} />
          </div>
          )}
      </div>
    </Drawer>
  );
};

export default DrawerReviews;
