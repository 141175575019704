import type { FC } from 'react';
import {
  lazy,
  Suspense,
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Route, Routes, useLocation, useNavigate, useSearchParams, Navigate,
} from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { IDidomiObject } from '@didomi/react';
import { DidomiSDK } from '@didomi/react';
import classes from './AppContent.module.scss';
import { CategoryProvider } from '@/context/Category/CategoryProvider';
import { LockerProvider } from '@/context/Locker/LockerProvider';
import { useResponsive } from '@/hooks/useResponsive';
import {
  Footer, Modal, NavBarDesktop, NavBarMobile, PopUpError, TopBarMobile,
} from '@/components';
import type { Tab } from '@/types/Component';
import { ROUTES } from '@/pages/routes';
import { HomePage, Landing } from '@/pages';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { RightsContext } from '@/context/Rights/RightsProvider';
import { AuthState } from '@/context/Auth/AuthState';
import { AuthContext } from '@/context/Auth/AuthProvider';
import Assets from '@/core/api/Assets';
import { RightsState } from '@/context/Rights/RightsState';
import { useTracking } from '@/hooks/useTracking';

// const Search = lazy(() => import('@/pages/Search'));
const LegalNotice = lazy(() => import('@/pages/LegalNotice'));
const FAQ = lazy(() => import('@/pages/FAQ'));
const CGU = lazy(() => import('@/pages/CGU'));
const CGA = lazy(() => import('@/pages/CGA'));
const CGL = lazy(() => import('@/pages/CGL'));
const Cookies = lazy(() => import('@/pages/Cookies'));
const Subscribe = lazy(() => import('@/pages/Subscribe'));
const Locker = lazy(() => import(/* webpackChunkName: "Locker" */ /* webpackPreload: true */ '@/pages/Locker'));
const SubscriptionSelection = lazy(() => import('@/pages/SubscriptionSelection'));
// const ProductList = lazy(() => import('@/pages/ProductList'));
const ResultSearch = lazy(() => import('@/pages/ResultSearch'));
const Product = lazy(() => import('@/pages/Product'));
const Return = lazy(() => import('@/pages/Return'));
const PersonalData = lazy(() => import('@/pages/PersonalData'));
const MobileAccount = lazy(() => import('@/pages/MobileAccount'));
const Contact = lazy(() => import('@/pages/Contact'));
const NotFound = lazy(() => import('@/pages/NotFound'));
const Cancellation = lazy(() => import('@/pages/Cancellation'));
const Cloture = lazy(() => import('@/pages/Cloture'));
const PopUpInactive = lazy(() => import('@/pages/Homepage/PopUpInactive').then((module) => ({ default: module.PopUpInactive })));
const PopUpUnknownUser = lazy(() => import('@/pages/Landing/PopUpUnknownUser').then((module) => ({ default: module.PopUpUnknownUser })));

const AppContent: FC = () => {
  const location = useLocation();
  const { isMobile } = useResponsive();
  const navigateTo = useNavigate();
  const { t } = useTranslation();
  const { clickEvent, viewEvent } = useTracking();
  const [easter, setEaster] = useState(false);
  const [openNavBar] = useState<boolean>(false);
  const [didomiObject, setDidomiObject] = useState<IDidomiObject>();

  const [searchParams] = useSearchParams();

  const { isActive, isUnknown } = useContext(RightsContext);
  const { isAuth } = useContext(AuthContext);

  useEffect(() => {
    viewEvent(location.pathname);
  }, [location, viewEvent]);

  const tabs: Tab[] = useMemo(() => ([
    {
      id: 0,
      label: t('navigation.home'),
      type: 'home',
      routePath: ROUTES.HOME_PAGE,
    },
    /* {
      id: 1,
      label: t('navigation.search'),
      type: 'search',
      routePath: ROUTES.SEARCH,
    }, */
    {
      id: 2,
      label: t('navigation.locker'),
      type: 'main',
      routePath: ROUTES.LOCKER,
    },
    {
      id: 3,
      label: t('navigation.account'),
      type: 'account',
      routePath: ROUTES.ACCOUNT,
    },
  ]), [t]);

  useEffect(() => {
    if (searchParams.has('target')) {
      navigateTo(searchParams.get('target') || ROUTES.HOME_PAGE);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateNavTab = useCallback((index: number) => {
    switch (index) {
      case 0:
        clickEvent(MATOMO_CATEGORY.HEADER.HOME);
        break;
      case 1:
        clickEvent(MATOMO_CATEGORY.HEADER.TREE);
        break;
      case 2:
        clickEvent(MATOMO_CATEGORY.HEADER.LOCKER);
        if (isActive === RightsState.INACTIVE) {
          navigateTo(ROUTES.SUBSCRIBE);
          return;
        }
        break;
      case 3:
        if (isAuth === undefined || isAuth === AuthState.DISCONNECTED) {
          navigateTo(ROUTES.SUBSCRIBE);
          return;
        }
        clickEvent(MATOMO_CATEGORY.HEADER.ACCOUNT);
        break;
      default:
    }
    const routePatch = tabs.find((t) => t.id === index);
    if (!routePatch) return;
    navigateTo(routePatch.routePath);
  }, [navigateTo, tabs, clickEvent, isActive, isAuth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === 'k') {
      setEaster(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const onDidomiReady = (didomi: IDidomiObject) => {
    setDidomiObject(didomi);
  };

  const showDidomi = () => {
    didomiObject?.preferences.hide();
    didomiObject?.preferences.show();
  };

  const isLanding = (): boolean => (location.pathname === ROUTES.LANDING);

  return (
    <div className={classes.root}>
      <DidomiSDK
        apiKey={REACT_APP_DIDOMI_API_KEY}
        iabVersion={2}
        gdprAppliesGlobally
        onReady={(didomi: IDidomiObject) => onDidomiReady(didomi)}
      />
      <CategoryProvider>
        <LockerProvider>
          <div>
            {easter && (
              <div className={classes.michu}><Modal
                title="C'est bon, j'ai fix !"
                onClose={() => setEaster(false)}
                onClickSecondButton={() => setEaster(false)}
                img={<img style={{ margin: 'auto' }} src={Assets.getAssets('/mdeMichu.png')} alt="Mde Michu" loading="lazy" />}
              />
              </div>
            )}
            {isMobile ? <TopBarMobile /> : <NavBarDesktop />}
            <Suspense>
              <div className={classNames({ [classes.navBar_is_open]: openNavBar && !isMobile }, classes.routes)}>
                <Routes>
                  {/* <Route path={ROUTES.PRODUCT_LIST} element={<ProductList />} /> */}
                  <Route path={ROUTES.PRODUCT} element={<Product />} />
                  <Route path={ROUTES.LOCKER} element={<Locker />} />
                  {/*  <Route path={ROUTES.SEARCH} element={<Search />} /> */}
                  <Route path={ROUTES.RETURN_PAGE} element={<Return />} />
                  <Route path={ROUTES.ACCOUNT} element={<MobileAccount />} />
                  <Route path={ROUTES.HOME_PAGE} element={<HomePage />} />
                  <Route path={ROUTES.CGL} element={<CGL />} />
                  <Route path={ROUTES.CGU} element={<CGU />} />
                  <Route path={ROUTES.CGA} element={<CGA />} />
                  <Route path={ROUTES.COOKIES} element={<Cookies showCookies={showDidomi} />} />
                  <Route path={ROUTES.FAQ} element={<FAQ />} />
                  <Route path={ROUTES.CONTACT} element={<Contact />} />
                  <Route path={ROUTES.LEGAL_NOTICE} element={<LegalNotice />} />
                  <Route path={ROUTES.PERSONAL_DATA} element={<PersonalData />} />
                  <Route path={ROUTES.SUBSCRIPTION_SELECTION} element={<SubscriptionSelection />} />
                  <Route path={ROUTES.SUBSCRIBE} element={<Subscribe />} />
                  <Route path={ROUTES.CANCELLATION} element={<Cancellation />} />
                  <Route path={ROUTES.LANDING} element={<Landing />} />
                  <Route path={ROUTES.RESULT_SEARCH} element={<ResultSearch />} />
                  <Route path={ROUTES.CLOTURE} element={<Cloture />} />
                  <Route path="/" element={<Navigate to={ROUTES.LANDING} replace />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </Suspense>
            <Footer />
            { isMobile && !isLanding() && !isUnknown && isAuth === AuthState.CONNECTED
              && <NavBarMobile tabs={tabs} onClick={updateNavTab} />}
          </div>
        </LockerProvider>
      </CategoryProvider>
      <PopUpError />
      <PopUpInactive />
      <PopUpUnknownUser />
    </div>
  );
};

export default AppContent;
