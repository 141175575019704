import type { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import { Spacer, WPCButton, Icon } from '@/components';
import { useResponsive } from '@/hooks/useResponsive';

type Props = {
  className?: string,
  validateSelection: () => void;
};

const ValidateDeliveryTimeButton: FC<Props> = ({ className, validateSelection }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <div className={className}>
      <Spacer vertical={isMobile ? 1 : 3} />
      <WPCButton
        onAction={validateSelection}
        size="stretched"
        variant="conversion"
        className={classes.validate}
      >
        { t('locker.selection.validate.action') }
      </WPCButton>
      <Spacer vertical={isMobile ? 1 : 3} />
      <div className={classNames(classes.delivery_time, 'vtmn-typo_text-2 vtmn-text-white')}>
        <Icon value="information-line" variant="primary-reversed" />
        <Spacer horizontal={1} />
        { t('locker.delivery_time') }
      </div>
      <Spacer vertical={isMobile ? 1 : 3} />
    </div>
  );
};

export default ValidateDeliveryTimeButton;
