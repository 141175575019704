import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Container';
import type { LockerItem } from '@/types/Locker';
import { LOCKER_STATE } from '@/constants/locker';
import { Icon } from '@/components';

type Props = {
  shipping: { [key: string]: LockerItem[] },
  reloadEquipment: () => void,
};

export const Shipping: FC<Props> = ({ shipping, reloadEquipment }) => {
  const { t } = useTranslation();

  return (
    <Container
      title={t('locker.state.shipping')}
      icon={(
        <Icon
          size={24}
          value="truck-line"
          variant="positive"
        />
)}
      subtitle={t('locker.state_subtitle.shipping')}
      reloadEquipment={reloadEquipment}
      data={shipping}
      state={LOCKER_STATE.SHIPPING}
    />
  );
};
