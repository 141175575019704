import type { FC } from 'react';
// import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// import { useNavigate } from 'react-router-dom';
import classes from './styles.module.scss';
// import { WPCButton, Icon } from '@/components';
// import { useResponsive } from '@/hooks/useResponsive';
// import { CategoryContext } from '@/context/Category/CategoryProvider';
// import { ROUTES } from '@/pages/routes';

const HistoryEmpty: FC = () => {
  const { t } = useTranslation();
  /* const { isMobile } = useResponsive();
  const navigateTo = useNavigate();
  const { setGoToFirstCategory } = useContext(CategoryContext);

  const openCategories = useCallback(() => {
    if (isMobile) {
      navigateTo(ROUTES.SEARCH);
      return;
    }
    setGoToFirstCategory(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <div>
      {/* <div className={classes.root}>
        <div className={classes.empty_info}>
          <div className={classNames('vtmn-typo_text-2', classes.empty_text)}>
            { t('locker.history.empty.title') }
          </div>
          <WPCButton
            onAction={() => { openCategories(); }}
            variant="primary"
            size={isMobile ? 'stretched' : 'medium'}
          >
            { t('actions.see_catalogue') }
          </WPCButton>
          <div className={classNames('vtmn-typo_text-2', classes.empty_text)}>
            { t('locker.history.empty.sub_title') }
          </div>
        </div>
      </div>
      {!isMobile
        && (
        <div className={classNames('vtmn-typo_text-3', classes.info)}>
          <Icon value="information-line" size={16} />
          <div className={classes.text}>
            { t('locker.history.empty.info') }
          </div>
        </div>
        )}
  */}
      <div className={classes.root}>
        <div className={classes.empty_info}>
          <div className={classNames('vtmn-typo_text-2', classes.empty_text)}>
            { t('locker.history.empty.cloture') }
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryEmpty;
