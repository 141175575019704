import type { FC } from 'react';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import classNames from 'classnames';
import classes from './styles.module.scss';
import { Icon } from '@/components';

type ReviewsIndicatorProps = {
  score?: number,
  scoreMax: number,
  nbReviews: number,
  icon: VitamixId,
  showReviews: () => void,
};

const ReviewsIndicator: FC<ReviewsIndicatorProps> = ({
  score, scoreMax, nbReviews, icon, showReviews,
}) => (
  <div className={classes.root}>
    <Icon value={icon} variant="default" size={16} />
    <div className={classNames('vtmn-typo_text-3 vtmn-font-bold', classes.score)}>
      {score} / {scoreMax}
    </div>
    <div className={classNames('vtmn-typo_text-3', classes.reviews)}>
      (<button onClick={showReviews} className={classes.clickable}>{nbReviews}</button>)
    </div>
  </div>
);

export default ReviewsIndicator;
