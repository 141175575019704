import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Drawer, Spacer, WPCButton, Icon,
} from '@/components';
import type { LockerItem } from '@/types/Locker';
import classes from './styles.module.scss';
import ProductSummary from './ProductSummary';
import { useResponsive } from '@/hooks/useResponsive';
import { CategoryContext } from '@/context/Category/CategoryProvider';
import { ROUTES } from '@/pages/routes';

type Props = {
  lockerItemsUnavailable: LockerItem[];
  closeDrawer: () => void;
  continueWithoutUnavailable: () => void;
};

const ErrorValidate: FC<Props> = ({ lockerItemsUnavailable, closeDrawer, continueWithoutUnavailable }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const navigateTo = useNavigate();
  const { setGoToFirstCategory } = useContext(CategoryContext);

  const [translate, setTranslate] = useState<string>(lockerItemsUnavailable.length > 1 ? 'multi_product' : 'one_product');

  const openCategories = useCallback(() => {
    closeDrawer();
    if (isMobile) {
      navigateTo(ROUTES.SEARCH);
      return;
    }
    setGoToFirstCategory(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTranslate(lockerItemsUnavailable.length > 1 ? 'multi_product' : 'one_product');
  }, [lockerItemsUnavailable]);

  return (
    <Drawer
      isOpen={lockerItemsUnavailable.length > 0}
      updateDrawer={closeDrawer}
      anchor={isMobile ? 'bottom' : 'right'}
      height={'{xs: "100%"}'}
    >
      <div className={classes.root_drawer}>
        <div className={classes.first_line}>
          <div className={classes.icon_title}>
            <Icon
              value="error-warning-line"
              variant="default"
              onClick={() => closeDrawer()}
              size={20}
            />
            <div className="vtmn-typo_title-5 vtmn-font-bold">
              { t(`locker.selection.validate.not_available.${translate}.title`) }
            </div>
          </div>
          <Icon
            value="close-line"
            variant="default"
            onClick={() => closeDrawer()}
            size={24}
            className={classes.button_close}
          />
        </div>
        <Spacer vertical={3} />
        <div className="vtmn-typo_text-2">
          { t(`locker.selection.validate.not_available.${translate}.subtitle`) }
        </div>
        <Spacer vertical={2} />
        { lockerItemsUnavailable.map((item, index) => (
          <div key={index}>
            <ProductSummary
              item={item}
            />
            <Spacer vertical={1} />
          </div>
        ))}
        <Spacer vertical={3} />
        <WPCButton
          onAction={openCategories}
          variant="secondary"
          size="stretched"
        >
          { t('actions.browse_catalog') }
        </WPCButton>
        <Spacer vertical={2} />
        <WPCButton
          variant="primary"
          size="stretched"
          onAction={continueWithoutUnavailable}
        >
          { t(`locker.selection.validate.not_available.${translate}.continue`) }
        </WPCButton>
      </div>
    </Drawer>
  );
};

export default ErrorValidate;
