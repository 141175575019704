import type { FC } from 'react';
import { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useTracking } from '@/hooks/useTracking';
import classes from '../styles.module.scss';
import { LockerContext } from '@/context/Locker/LockerProvider';
import { ROUTES } from '@/pages/routes';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { RightsContext } from '@/context/Rights/RightsProvider';
import Assets from '@/core/api/Assets';
import { RightsState } from '@/context/Rights/RightsState';

const GarageDesktopIcon = Assets.getAssets('/navigation/garage_desktop.svg');
const GarageDesktopSelectedIcon = Assets.getAssets('/navigation/garage_desktop_selected.svg');
const LockerNavigation: FC = () => {
  const { t } = useTranslation();
  const { clickEvent } = useTracking();
  const location = useLocation();

  const { nbItemsInLocker } = useContext(LockerContext);
  const { isActive } = useContext(RightsContext);

  const isInLocker = useCallback(() => location.pathname === ROUTES.LOCKER, [location]);

  const gotToLocker = useCallback(() => {
    clickEvent(MATOMO_CATEGORY.HEADER.LOCKER);
  }, [clickEvent]);

  return (
    <Link to={isActive === RightsState.ACTIVE ? ROUTES.LOCKER : ROUTES.SUBSCRIBE} className={classes.icon_text} onClick={gotToLocker}>
      <div className={classes.icon_content}>
        { nbItemsInLocker > 0
          && (
          <div className={classNames('vtmn-typo_text-3 vtmn-font-bold', classes.nb_items)}>
            {nbItemsInLocker}
          </div>
          )}
        <img
          alt="icon"
          src={isInLocker() ? GarageDesktopSelectedIcon : GarageDesktopIcon}
        />
      </div>
      <span className={classNames(classes.text, 'vtmn-typo_caption-1', {
        [classes.selected]: isInLocker(), 'vtmn-font-bold': isInLocker(),
      })}
      >
        { t('navigation.locker') }
      </span>
    </Link>
  );
};

export default LockerNavigation;
