import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../Container';
import { LOCKER_STATE } from '@/constants/locker';
import type { LockerItem } from '@/types/Locker';
import { Icon } from '@/components';

type Props = {
  toReturn: { [key: string]: LockerItem[] },
  reloadEquipment: () => void;
};

export const ToReturn: FC<Props> = ({ toReturn, reloadEquipment }) => {
  const { t } = useTranslation();

  return (
    <Container
      title={t('locker.state.to_return')}
      icon={(
        <Icon
          size={24}
          value="arrow-go-back-line"
          variant="negative"
        />
)}
      reloadEquipment={reloadEquipment}
      data={toReturn}
      state={LOCKER_STATE.TO_RETURN}
    />
  );
};
