import type { FC, CSSProperties } from 'react';
import classes from './styles.module.scss';

type ReviewProgressBarProps = {
  rating: number,
};

const ReviewProgressBar: FC<ReviewProgressBarProps> = ({ rating }) => (
  <div className={classes.progress_bar_div}>
    <div
      className={classes.progress_bar}
      style={{ '--width_progress_bar': (`${(rating / 5) * 100}%`) } as CSSProperties}
    />
  </div>
);

export default ReviewProgressBar;
