import type { FC } from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';
import type { Information } from '@/types/Model';

type GenericInformationsProps = {
  informations: Information[],
};

const GenericInformations: FC<GenericInformationsProps> = ({ informations }) => (
  <div className={classes.root}>
    { informations.map((i, index) => (
      <div className={classes.information} key={index}>
        { i.urlImg
          ? (
            <div className={classes.img}>
              <img src={i.urlImg} alt="ImgGenericInformation" />
            </div>
          ) : null}
        <div className={classNames({ [classes.information_with_img]: i.urlImg })}>
          <div className="vtmn-typo_text-1 vtmn-font-bold">
            { i.title }
          </div>
          <div className="vtmn-typo_text-2">
            { i.description }
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default GenericInformations;
