import type { CSSProperties, FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '@/hooks/useResponsive';
import {
  WPCButton, Drawer, Gauge, Icon,
} from '@/components';
import classes from './styles.module.scss';
import type { SimpleModel } from '@/types/Model';
import ModelInfo from './ModelInfo';
import { ROUTES } from '../../routes';
import currencyFormatter from '@/utils/currencyFormatter';
import { AuthRepository } from '@/repositories';

type ReturnAddSelectionProps = {
  isError: boolean,
  updateIsOpen: (isOpen: boolean) => void,
  displaySecondButton?: boolean,
  model: SimpleModel[],
  gaugeValue: number,
  remainingSize: number,
};

const InfosAfterAddInSelection : FC<ReturnAddSelectionProps> = ({
  isError, updateIsOpen, model, displaySecondButton = true, gaugeValue, remainingSize,
}) => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const navigateTo = useNavigate();

  const clickOnFirstButton = useCallback(() => {
    if (isError) {
      navigateTo(ROUTES.LOCKER);
    }
    updateIsOpen(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clickOnSecondButton = useCallback(async () => {
    if (isError) {
      const sub = await AuthRepository.getMemberId();
      window.location.href = `${REACT_APP_ZIQY}?page=ACCOUNT&member_id=${sub}`;
    } else navigateTo(ROUTES.LOCKER);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const title = model.length === 1 ? 'product.add_to_selection.success.title' : 'product.add_to_selection.success.titles';

  return (
    <Drawer
      isOpen
      updateDrawer={updateIsOpen}
      borderTopRightRadius={isMobile ? 16 : 0}
      borderTopLeftRadius={isMobile ? 16 : 0}
      anchor={isMobile ? 'bottom' : 'right'}
    >
      <div className={classes.root}>
        <div className={classes.icons_title}>
          <div className={classes.icon_title}>
            <Icon value="information-line" size={20} variant={isError ? 'default' : 'positive'} />
            <span className={classNames('vtmn-typo_title-4', classes.title)}>
              { t(isError ? 'product.add_to_selection.error.title' : title) }
            </span>
          </div>
          <Icon
            value="close-line"
            variant="default"
            onClick={() => updateIsOpen(false)}
            size={24}
            className={classes.button_close}
          />
        </div>
        <ModelInfo model={model} />
        <div className={classes.gauge}>
          <div
            className={classNames('vtmn-typo_text-3 vtmn-text-white', isError && classes.is_error, gaugeValue - 10 > 80 ? classes.left : classes.is_success)}
            style={{ '--left-gauge': `${gaugeValue > 10 ? gaugeValue - 10 : 0}%` } as CSSProperties}
          >
            <div>
              {currencyFormatter(remainingSize)}
              {t('product.add_to_selection.gauge')}
            </div>
          </div>
          <Gauge gaugeValue={gaugeValue} />
        </div>
        { isError && (
        <div className={classes.description}>
          { t('product.add_to_selection.error.description') }
        </div>
        )}
        <div className={classes.button}>
          <WPCButton
            onAction={() => clickOnFirstButton()}
            size="stretched"
            className={classes.first}
            variant="secondary"
          >
            { t(`product.add_to_selection.${isError ? 'error' : 'success'}.first_button`)}
          </WPCButton>
          { displaySecondButton
            && (
            <WPCButton
              onAction={() => clickOnSecondButton()}
              className={classes.second}
              size="stretched"
              variant="primary"
            >
              { t(`product.add_to_selection.${isError ? 'error' : 'success'}.second_button`)}
            </WPCButton>
            )}
        </div>
      </div>
    </Drawer>
  );
};

export default InfosAfterAddInSelection;
