import type { FC } from 'react';
import {
  useCallback, useEffect, useLayoutEffect, useState, useContext,
} from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import GetUserLockerHistoryUseCase from '@/usecases/GetUserLockerHistoryUseCase';
import HistoryEmpty from './HistoryEmpty';
import HistoryLine from './HistoryLine';
import classes from './styles.module.scss';
import { ModalLocker, Loader } from '@/components';
import type { LockerItem } from '@/types/Locker';
import { ErrorManagementContext } from '@/context/ErrorManagement/ErrorManagementProvider';
import { LanguageContext } from '@/context/Language/LanguageProvider';

const PER_PAGE = 20;

const History: FC = () => {
  const [totalItem, setTotalItem] = useState<number>(0);
  const [items, setItems] = useState<LockerItem[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedLockerItem, setSelectedLockerItem] = useState<LockerItem | null>(null);
  const [nbPage, setNbPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState(false);

  const { setIsError } = useContext(ErrorManagementContext);
  const { updateLanguage, setUpdateLanguage } = useContext(LanguageContext);

  const updateItems = useCallback((async (page: number) => {
    try {
      setLoader(true);
      const newItems = await GetUserLockerHistoryUseCase.execute({ page_nb: page });
      setTotalItem(newItems.total);
      if (page === 1) setItems(newItems.items);
      else setItems((prevItems) => ([...prevItems, ...newItems.items]));
      setLoader(false);
    } catch {
      setIsError(true);
    }
  }), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (!updateLanguage) return;
      setNbPage(1);
      await updateItems(1);
      setUpdateLanguage(false);
    })();
  }, [updateLanguage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => { await updateItems(nbPage); })();
  }, [nbPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const setAndOpenModal = useCallback((index: number) => {
    if (!items) return;
    const item = items[index];
    if (!item) return;
    setSelectedLockerItem(item);
    setIsOpen(true);
  }, [items]);

  const updateNbPage = useCallback(() => {
    if (nbPage * PER_PAGE >= totalItem) return;
    setNbPage((nbPage) => nbPage + 1);
  }, [nbPage, totalItem]);

  useLayoutEffect(() => {
    const div = document.getElementById('History');
    const scroll = document.querySelector('#History');
    if (!div || !scroll) return;

    scroll.addEventListener('scroll', async () => {
      if (div.scrollTop + div.offsetHeight >= div.scrollHeight) {
        updateNbPage();
      }
    });
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classNames(classes.root, { [classes.background_loader]: loader })}>
      { loader && <Loader className={classes.loader} size="large" />}
      { items.length > 0
        ? (
          <div className={classes.all_lignes} id="History">
            <Grid container>
              { items.map((item, index) => (
                <HistoryLine
                  onClick={() => setAndOpenModal(index)}
                  key={item.uuid}
                  item={item}
                  index={index}
                  historyLength={items.length}
                />
              ))}
            </Grid>
          </div>
        )
        : <HistoryEmpty />}
      <ModalLocker
        selectedProduct={selectedLockerItem}
        setSelectedProduct={setSelectedLockerItem}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
    </div>
  );
};

export default History;
