import type { FC } from 'react';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { LockerItem } from '@/types/Locker';
import GetUserLockerUseCase from '@/usecases/GetUserLockerUseCase';
import styles from './styles.module.scss';
import {
  ModalLocker, PromotionalPriceAndPrice, Spacer, WPCButton, Loader,
} from '@/components';
import { ReturnContext } from '@/context/Return/ReturnProvider';
import { ROUTES } from '@/pages/routes';
import PopUpSinister from './PopUpSinister';
import { ErrorManagementContext } from '@/context/ErrorManagement/ErrorManagementProvider';
import { LanguageContext } from '@/context/Language/LanguageProvider';
import { AuthContext } from '@/context/Auth/AuthProvider';
import { AuthState } from '@/context/Auth/AuthState';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { useTracking } from '@/hooks/useTracking';

export const MyLocker: FC = () => {
  const { t } = useTranslation();
  const [myLocker, setMyLocker] = useState<LockerItem[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<LockerItem | null>(null);
  const { setItemToReturn } = useContext(ReturnContext);
  const navigateTo = useNavigate();
  const { isAuth } = useContext(AuthContext);
  const { clickEvent } = useTracking();

  const { setIsError } = useContext(ErrorManagementContext);
  const { updateLanguage, setUpdateLanguage } = useContext(LanguageContext);

  const openProduct = useCallback((lockerItem: LockerItem) => {
    clickEvent(MATOMO_CATEGORY.LOCKER.OVERLAY);
    setSelectedProduct(lockerItem);
    setIsOpen(true);
  }, [clickEvent]);

  useEffect(() => {
    if (isAuth === AuthState.CONNECTED) {
      (async () => {
        await loadLocker();
      })();
    }
  }, [isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLocker = useCallback(async () => {
    const fetch = async () => {
      setLoader(true);
      const lockerItems = await GetUserLockerUseCase.execute();
      setMyLocker(lockerItems);
      setLoader(false);
    };
    fetch().catch(() => setIsError(true));
  }, [setIsError]);

  useEffect(() => {
    if (isAuth === AuthState.CONNECTED) {
      (async () => {
        if (!updateLanguage) return;
        await loadLocker();
        setUpdateLanguage(false);
      })();
    }
  }, [updateLanguage, isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!myLocker.length) return null;

  const onReturn = (item: LockerItem) => {
    setItemToReturn(item);
    navigateTo(ROUTES.RETURN_PAGE);
  };

  const openSinisterPopUp = () => {
    clickEvent(MATOMO_CATEGORY.LOCKER.SINISTER);
    setOpenPopUp(true);
  };

  return (
    <>
      <Spacer vertical={3} />
      <div
        className={classnames('vtmn-typo_title-5', styles.title)}
      >
        {t('locker.my_locker.title')}
      </div>
      <Spacer vertical={2} />
      {loader && <Loader className={styles.loader} size="large" />}
      <div className={styles.lines}>
        {myLocker.map((lockerItem) => (
          <div key={lockerItem.uuid} className={styles.line}>
            <button className={styles.leftSide} onClick={() => openProduct(lockerItem)}>
              <div className={styles.infos}>
                <img src={lockerItem.cover} width={48} alt="" />
                <div className={styles.grid}>
                  <div className="vtmn-font-bold vtmn-typo_text-3">
                    {lockerItem.brandLabel}
                  </div>
                  <div
                    className={classnames(
                      'vtmn-typo_caption-1',
                      styles.breakWord,
                    )}
                  >
                    {lockerItem.webLabel}
                    -
                    {lockerItem.colorLabel}
                  </div>
                  <div className="vtmn-typo_caption-1 vtmn-text-content-secondary">
                    { t('locker.size', { size: lockerItem.size })}
                  </div>
                </div>
              </div>
              <div className={styles.price}>
                <div className="vtmn-typo_caption-1">
                  {new Date(lockerItem.validatedAt).toLocaleDateString()}
                </div>
                <div>
                  <PromotionalPriceAndPrice
                    displayLabel={false}
                    realPrice={lockerItem.price}
                  />
                </div>
              </div>
            </button>

            <div className={styles.divider} />

            <div className={styles.rightSide}>
              <WPCButton
                onAction={openSinisterPopUp}
                variant="tertiary"
                size="small"
                iconRight="external-link-line"
              >
                { t('locker.my_locker.sinister') }
              </WPCButton>
              <WPCButton
                onAction={() => onReturn(lockerItem)}
                variant="tertiary"
                size="small"
                iconRight="return-line"
              >
                { t('locker.my_locker.return') }
              </WPCButton>
            </div>
          </div>
        ))}
      </div>
      <ModalLocker
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <PopUpSinister isOpen={openPopUp} onClose={() => setOpenPopUp(false)} />
    </>
  );
};
