import type { FC } from 'react';
import { Spacer, Icon } from '@/components';
import classes from './styles.module.scss';

type ConditionProps = {
  children: JSX.Element | string
};

const Condition: FC<ConditionProps> = ({ children }) => (
  <div>
    <Spacer vertical={2} />
    <div className={classes.root}>
      <Icon
        value="check-fill"
        variant="positive"
      />
      <Spacer horizontal={1.5} />
      <span className="vtmn-typo_text-3">
        { children }
      </span>
    </div>
  </div>
);

export default Condition;
