import type { FC } from 'react';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import classes from './styles.module.scss';
import type { Color } from '@/types/Component';

type ColorSelectedProps = {
  colors: Color[],
  onUpdateColor: (colorIndex: string) => void,
  initialColorSelected?: Color,
};

const ColorSelector: FC<ColorSelectedProps> = ({ colors, onUpdateColor, initialColorSelected }) => {
  const { t } = useTranslation();

  const [colorSelected, setColorSelected] = useState<Color | null>(initialColorSelected || null);

  useEffect(() => {
    if (!colorSelected) return;
    onUpdateColor(colorSelected.index);
  }, [colorSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateColorLabel();
  }, [colors]);// eslint-disable-line react-hooks/exhaustive-deps

  const updateColorLabel = useCallback(() => {
    if (!colorSelected) return;
    const newTradColor = colors.find((c) => c.index === colorSelected.index);
    if (!newTradColor || newTradColor.label === colorSelected.label) return;
    setColorSelected(newTradColor);
  }, [colors]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className={classNames('vtmn-typo_text-2', classes.color_information)}>
        {t('color.color')}
        <span className="vtmn-font-bold">{colorSelected ? colorSelected.label : ''}</span>
      </div>
      <div className={classes.color_cards}>
        {colors.map((color, index) => (
          <button
            key={index}
            onClick={() => setColorSelected(color)}
            className={classNames(classes.color, { [classes.border_select]: colorSelected && color.index === colorSelected.index })}
          >
            <img src={color.url} alt="ImgColor" />
          </button>
        ))}
      </div>
    </div>
  );
};

export default ColorSelector;
