import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import type { ElementList } from '@/types/Component';
import classes from './styles.module.scss';
import {
  Icon,
  List,
  ListItem,
  ListItemStart,
  ListItemText,
  ListItemEndAction,
} from '@/components';

type SectionListProps = {
  elements: ElementList[],
  onClick: (action: string) => void;
};

const SectionList: FC<SectionListProps> = ({ elements, onClick }) => {
  const { t } = useTranslation();

  return (
    <List divider className={classes.root}>
      {elements.map((e, index) => (
        <ListItem onClick={() => onClick(e.action)} key={index}>
          <> {e.iconStart || e.imgStart
            ? (
              <ListItemStart>
                {e.iconStart
                  ? <Icon value={e.iconStart} size={20} />
                  : <img src={e.imgStart} alt="ImgStart" />}
              </ListItemStart>
            )
            : null}
          </>
          <ListItemText className={classNames('vtmn-typo_text-1 vtmn-font-bold', classes.text_list)}>
            {t(e.text)}
          </ListItemText>
          <> {e.iconEnd || e.imgEnd
            ? (
              <ListItemEndAction>
                {e.iconEnd
                  ? <Icon value={e.iconEnd} size={20} />
                  : <img src={e.imgEnd} alt="ImgEnd" />}
              </ListItemEndAction>
            )
            : null}
          </>
        </ListItem>
      ))}
    </List>
  );
};

export default SectionList;
