import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { VitamixId } from '@vtmn/icons/dist/vitamix/font/vitamix';
import type { VtmnIconVariant } from '@vtmn/react/dist/src/guidelines/iconography/VtmnIcon/types';
import dateFormatter from '@/utils/dateFromatter';
import { Icon } from '@/components';

type StatusProps = {
  icon: VitamixId,
  variantIcon: VtmnIconVariant,
  trad: string,
  date: string,
};

const Status: FC<StatusProps> = ({
  icon, variantIcon, trad, date,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <Icon
          size={16}
          value={icon}
          variant={variantIcon}
        />
      </div>
      <span className="vtmn-font-bold">{t(trad)}</span>
      {dateFormatter(date)}
    </div>
  );
};

export default Status;
