import type { FC } from 'react';
import moment from 'moment';
import {
  useCallback, useContext, useEffect, useState, useMemo,
} from 'react';
import classNames from 'classnames';
import classes from './styles.module.scss';
import { Confirmed } from './Confirmed';
import { ToReturn } from './ToReturn';
import { ToCollect } from './ToCollect';
import { InPreparation } from '@/pages/Locker/Equipment/InPreparation';
import { Shipping } from '@/pages/Locker/Equipment/Shipping';
import type { LockerItem } from '@/types/Locker';
import { LOCKER_STATE } from '@/constants/locker';
import GetUserEquipementUseCase from '@/usecases/GetUserEquipementUseCase';
import { ErrorManagementContext } from '@/context/ErrorManagement/ErrorManagementProvider';
import { LanguageContext } from '@/context/Language/LanguageProvider';
import { AuthContext } from '@/context/Auth/AuthProvider';
import { AuthState } from '@/context/Auth/AuthState';
import sortingUtils from '@/utils/sortingUtils';
import { Loader } from '@/components';

export const Equipment: FC = () => {
  const [lockerData, setLockerData] = useState<LockerItem[] | []>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { isAuth } = useContext(AuthContext);
  const { setIsError } = useContext(ErrorManagementContext);
  const { updateLanguage, setUpdateLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (isAuth === AuthState.CONNECTED) {
      (async () => {
        await reloadEquipment();
      })();
    }
  }, [isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuth === AuthState.CONNECTED) {
      (async () => {
        if (!updateLanguage) return;
        await reloadEquipment();
        setUpdateLanguage(false);
      })();
    }
  }, [updateLanguage, isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const reloadEquipment = useCallback(async () => {
    setLoader(true);
    const fetch = async () => {
      const data = await GetUserEquipementUseCase.execute();
      setLockerData(data);
      setLoader(false);
    };
    fetch()
      .catch(() => { setIsError(true); });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const groupByDate = useCallback((locker: LockerItem[]): { [key: string]: LockerItem[] } => locker?.sort((a, b) => sortingUtils.sortByDate(a.validatedAt, b.validatedAt, 'desc'))
    .reduce((r: any, a) => {
      r[a.validatedAt?.toString()] = r[a.validatedAt?.toString()] || [];
      r[a.validatedAt?.toString()].push(a);
      return r;
    }, {}), []);

  const confirmed = useMemo(() => lockerData?.filter((l) => l.state === LOCKER_STATE.VALIDATED), [lockerData]);
  const inPreparation = useMemo(() => lockerData?.filter((l) => l.state === LOCKER_STATE.IN_PREPARATION
    || (LOCKER_STATE.SHIPPING && moment(l.shippingAt).isAfter(moment().subtract(1, 'd')))), [lockerData]);
  const shipping = useMemo(() => lockerData?.filter((l) => l.state === LOCKER_STATE.SHIPPING && moment(l.shippingAt).isBefore(moment().subtract(1, 'days'))), [lockerData]);
  const toCollect = useMemo(() => lockerData?.filter((l) => l.state === LOCKER_STATE.TO_COLLECT), [lockerData]);
  const toReturn = useMemo(() => lockerData?.filter((l) => l.state === LOCKER_STATE.TO_RETURN), [lockerData]);

  const memoConfirmed = useMemo(() => <Confirmed confirmed={groupByDate(confirmed)} reloadEquipment={reloadEquipment} />, [confirmed, groupByDate, reloadEquipment]);
  const memoInPreparation = useMemo(() => <InPreparation inPreparation={groupByDate(inPreparation)} reloadEquipment={reloadEquipment} />, [inPreparation, groupByDate, reloadEquipment]);
  const memoShipping = useMemo(() => <Shipping shipping={groupByDate(shipping)} reloadEquipment={reloadEquipment} />, [shipping, groupByDate, reloadEquipment]);
  const memoToCollect = useMemo(() => <ToCollect toCollect={groupByDate(toCollect)} reloadEquipment={reloadEquipment} />, [toCollect, groupByDate, reloadEquipment]);
  const memoToReturn = useMemo(() => <ToReturn toReturn={{ '': toReturn }} reloadEquipment={reloadEquipment} />, [toReturn, reloadEquipment]);

  return (
    <div className={classNames(classes.root, { [classes.background_loader]: loader })}>
      { loader && <Loader className={classes.loader} size="large" />}
      <div className={classes.container}>
        {confirmed.length > 0 && memoConfirmed}
        {inPreparation.length > 0 && memoInPreparation}
        {shipping.length > 0 && memoShipping}
        {toCollect.length > 0 && memoToCollect}
        {toReturn.length > 0 && memoToReturn}
      </div>
    </div>
  );
};
