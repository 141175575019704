import type { FC } from 'react';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { RightsContext } from '@/context/Rights/RightsProvider';
import type { Model as ModelType, Size } from '@/types/Model';
import {
  WPCButton, PromotionalPriceAndPrice, Spacer, PopUpSubscribe, PopUpDefaultPayment, Icon,
} from '@/components';
import Quantity from './Quantity';
import ReviewsIndicator from './ReviewsIndicator';
import ColorSelector from './ColorSelector';
import SizeSelector from '@/components/SizeSelector';
import classes from './styles.module.scss';
import DetailSaleInformations from './DetailSaleInformations';
import { useResponsive } from '@/hooks/useResponsive';
import GoogleAnalytics from '@/service/GA/GA';
import { GaEvents } from '@/enum/GA';
import { GA_LABELS } from '@/types/GA';
import DrawerReviews from '@/pages/Product/ProductAndSaleInfo/ProductInformations/ProductCardInfo/DrawerReviews';
import Locale from '@/core/api/Locale';
import type { CreateUserSelection } from '@/types/Locker';
import { RightsState } from '@/context/Rights/RightsState';
import LocalStorage from '@/utils/LocalStorage';
import BearerToken from '@/core/BearerToken';

type SaleInformationsProps = {
  model: ModelType,
  onUpdateColor: (colorIndex: string) => void,
  setPropertyGenericInfo: (property: string) => void,
  onClickAddSelection: (infos: CreateUserSelection, model: ModelType[]) => void,
};

const ANAIS = 'cc40dcdc-8b3a-4106-b1b3-b34f63bd1000';
const ANAIS2 = '283c12e3-3cf0-47fd-a640-92f3e6d819b1';
const SJTIN = '8f74aa30-6be9-4a86-b4bf-c3222faacd03';

const SaleInformations: FC<SaleInformationsProps> = ({
  model, onUpdateColor, onClickAddSelection, setPropertyGenericInfo,
}) => {
  const { t } = useTranslation();
  const { isDefaultPayment, isActive } = useContext(RightsContext);
  const { isMobile } = useResponsive();

  const [sizeSelected, setSizeSelected] = useState<Size | null>(null);
  const [quantitySelected, setQuantitySelected] = useState<number>(1);
  const [disabledAddGarage, setDisabledAddGarage] = useState<boolean>(true);
  const [openDrawerReviews, setOpenDrawerReviews] = useState<boolean>(false);
  const [openPopUpSubscribe, setOpenPopUpSubscribe] = useState(false);
  const [openPopUpDefaultPayment, setOpenPopUpDefaultPayment] = useState<boolean>(false);

  const lng = Locale.getCountry();

  const addToLockerSelection = useCallback(() => {
    if (isDefaultPayment) {
      setOpenPopUpDefaultPayment(true);
      return;
    }
    if (isActive === RightsState.INACTIVE || isActive === RightsState.UNKNOWN) {
      setOpenPopUpSubscribe(true);
      return;
    }
    if (!sizeSelected) return;
    const indexSize = model.size.findIndex((size) => size.size === sizeSelected.size);
    const itemId = model.itemIds[indexSize > -1 ? indexSize : 0];
    GoogleAnalytics.hit(GaEvents.CLICK, {
      label: GA_LABELS.PRODUCT_DETAIL.ADD_LOCKER,
      item_id: itemId,
      model_id: model.modelCode,
      item_name: model.label,
      price: model.currentPrice,
      currency: '€',
      quantity: quantitySelected,
    });
    onClickAddSelection({
      superModelCode: model.superModelCode,
      modelCode: model.modelCode,
      itemId: model.itemIds[indexSize],
      quantity: quantitySelected,
    }, [model]);
  }, [sizeSelected, quantitySelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setQuantitySelected(1);
    const token = LocalStorage.getToken();
    if (!token) return;
    const decodeToken = BearerToken.decode(token);
    if (!decodeToken || !decodeToken.sub) return;
    // eslint-disable-next-line no-console
    console.log(decodeToken.sub);
    setDisabledAddGarage(!([ANAIS, SJTIN, ANAIS2].includes(decodeToken.sub) && sizeSelected));
  }, [sizeSelected]);

  useEffect(() => {
    setSizeSelected(model.size.length === 1 && model.size[0].maxQuantity > 0 ? model.size[0] : null);
  }, [model]);

  const openReviews = useCallback(() => {
    if (isMobile) setPropertyGenericInfo('reviews');
    else setOpenDrawerReviews(true);
  }, [isMobile, setPropertyGenericInfo]);

  return (
    <div>
      {isMobile && <Spacer vertical={2} />}
      <div className={classNames('vtmn-font-bold vtmn-typo_text-2', classes.brand_label)}>
        { model.brand }
      </div>
      <div className="vtmn-font-bold vtmn-typo_title-3">
        { model.label }
      </div>
      <Spacer vertical={2} />
      <div className={classes.reviews_price}>
        <ReviewsIndicator
          score={model.reviews.globalRating}
          scoreMax={5}
          nbReviews={model.reviews.nbReviews}
          icon="star-fill"
          showReviews={openReviews}
        />
        { model.currentPrice
          ? <PromotionalPriceAndPrice realPrice={model.currentPrice} promotionalPrice={model.promotionalPrice} />
          : null}
      </div>
      <div className={classes.color_selector}>
        <ColorSelector
          colors={model.colors}
          initialColorSelected={model.modelColor}
          onUpdateColor={(model_code) => { onUpdateColor(model_code); }}
        />
      </div>
      {
        model.currentPrice < 2000 ? (
          <>
            <div className={classes.size_selector}>
              { model.size.length === 1 && model.size[0].maxQuantity === 0
                ? (
                  <div className={classes.size_unavailable}>
                    <Icon value="checkbox-blank-circle-fill" variant="negative" size={16} />
                    <span className="vtmn-typo_text-2">{t('product.size_not_available')}</span>
                  </div>
                ) : (model.size.length > 1 && (
                  <SizeSelector
                    sizes={model.size}
                    onUpdateSize={setSizeSelected}
                    initialSizeSelected={sizeSelected}
                  />
                ))}
            </div>
            <div className={classes.quantity_selector}>
              <Quantity
                disabled={!sizeSelected}
                quantityMin={1}
                quantityMax={sizeSelected ? sizeSelected.maxQuantity : 1}
                onUpdateQuantity={setQuantitySelected}
                initialQuantity={quantitySelected}
              />
            </div>
            <div className={classes.button_garage_add}>
              <WPCButton
                onAction={() => { addToLockerSelection(); }}
                iconLeft="add-fill"
                variant="conversion"
                size="stretched"
                disabled={disabledAddGarage}
                className={classNames(classes.add, disabledAddGarage && classes.disabled_add)}
              >
                { t('product.add_garage') }
              </WPCButton>
            </div>
            <br />
            <DetailSaleInformations />
          </>
        ) : (
          <>
            <div className={classes.button_garage_add}>
              <Link target="_blank" to={`${REACT_APP_DECATHLON}/${lng.languages}/search?Ntt=${model.modelCode}`}>
                <WPCButton
                  onAction={() => null}
                  variant="conversion"
                  size="stretched"
                  iconRight="external-link-fill"
                  className={classNames(classes.add, disabledAddGarage)}
                >
                  { t('product.discover_cta') }
                </WPCButton>
              </Link>
            </div>
            <Spacer vertical={2.4} />
            <div className={classes.card}>
              <Icon
                size={20}
                value="information-line"
                variant="default"
              />
              <div>{t('product.discover')} <a className={classes.link} href={REACT_APP_DECATHLON} target="_blank" rel="noreferrer">{t('product.discover_link')}</a></div>
            </div>
          </>
        )
      }
      <DrawerReviews
        model={model}
        isOpen={openDrawerReviews}
        updateSwipeableDrawer={setOpenDrawerReviews}
      />
      <PopUpSubscribe
        openPopUp={openPopUpSubscribe}
        closePopUp={() => setOpenPopUpSubscribe(false)}
      />
      <PopUpDefaultPayment
        openPopUp={openPopUpDefaultPayment}
        closePopUp={() => setOpenPopUpDefaultPayment(false)}
      />
    </div>
  );
};

export default SaleInformations;
