import type {
  Dispatch,
  FC,
  SetStateAction,
} from 'react';
import {
  useCallback,
  useContext,
  useState,
} from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import SelectionEmpty from './SelectionEmpty';
import GetUserSelectionUseCase from '@/usecases/GetUserSelectionUseCase';
import { useResponsive } from '@/hooks/useResponsive';
import SelectionHeader from './SelectionHeader';
import SelectionLigne from './SelectionLigne';
import classes from './styles.module.scss';
import type { LockerItem } from '@/types/Locker';
import { Modal, Loader } from '@/components';
import DeleteItemUserSelectionUseCase from '@/usecases/DeleteItemUserSelectionUseCase';
import { LockerContext } from '@/context/Locker/LockerProvider';
import { ErrorManagementContext } from '@/context/ErrorManagement/ErrorManagementProvider';

type Props = {
  updateGauge: () => void;
  items: LockerItem[];
  loader: boolean;
  setLoader: Dispatch<SetStateAction<boolean>>;
  setItems: Dispatch<SetStateAction<LockerItem[]>>;
};

const Selection: FC<Props> = ({
  items,
  loader,
  updateGauge,
  setLoader,
  setItems,
}) => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const { updateNbItemsInLocker } = useContext(LockerContext);
  const { setIsError } = useContext(ErrorManagementContext);
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);

  const deleteItem = useCallback(async () => {
    try {
      if (!itemToDelete) return;
      setLoader(true);
      await DeleteItemUserSelectionUseCase.execute({ uuid: itemToDelete });
      setItemToDelete(null);
      setItems(await GetUserSelectionUseCase.execute());
      updateNbItemsInLocker();
      updateGauge();
      setLoader(false);
    } catch {
      setIsError(true);
    }
  }, [itemToDelete, setItems, setLoader, updateGauge, updateNbItemsInLocker]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classNames(classes.root, {
        [classes.background_loader]: loader,
      })}
    >
      {loader && <Loader className={classes.loader} size="large" />}
      {items.length === 0 ? (
        <SelectionEmpty />
      ) : (
        <div>
          <Grid container spacing={2}>
            {!isMobile && <SelectionHeader />}
            {items.map((item) => (
              <SelectionLigne
                key={item.uuid}
                item={item}
                openModalDelete={setItemToDelete}
              />
            ))}
          </Grid>
        </div>
      )}

      {itemToDelete && (
        <Modal
          title={t('locker.selection.delete.title')}
          onClose={() => {
            setItemToDelete(null);
          }}
          description={t('locker.selection.delete.description')}
          titleFirstButton={t('actions.cancel')}
          onClickFirstButton={() => {
            setItemToDelete(null);
          }}
          iconSecondButton="delete-bin-line"
          titleSecondButton={t('actions.delete')}
          onClickSecondButton={deleteItem}
        />
      )}
    </div>
  );
};

export default Selection;
