import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LOCKER_STATE } from '@/constants/locker';
import classes from './styles.module.scss';
import { WPCButton, Icon } from '@/components';

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  isOpen: boolean,
  state: string,
  title: string,
  subtitle?: string,
  date: string,
  icon: any,
  updateStatus: () => void,
};
export const Header: FC<Props> = ({
  setIsOpen,
  isOpen,
  state,
  title,
  date,
  icon,
  updateStatus,
  subtitle,
}) => {
  const { t } = useTranslation();

  const getBadgeColor = useCallback((state: string) => (state === LOCKER_STATE.TO_RETURN ? classes.negative_badge : classes.positive_badge), []);

  return (
    <button className={classes.header} onClick={() => setIsOpen((isOpen) => !isOpen)}>
      <div className={classnames(classes.title, { [classes.to_return_title]: state === LOCKER_STATE.TO_RETURN })}>
        <div className={classnames(classes.icon, getBadgeColor(state))}>
          {icon}
        </div>
        <div>
          <div className="vtmn-typo_text-2 vtmn-font-bold">{title}</div>
          { state !== LOCKER_STATE.TO_RETURN
            && (
              <div>
                <div className={classnames('vtmn-typo_caption-1')}>
                  <span>{t('locker.validated_at')}</span>
                  {new Date(date).toLocaleDateString()}
                </div>
                <div className={classnames('vtmn-typo_caption-1', classes.subtitle)}> {subtitle} </div>
              </div>
            )}
        </div>
      </div>
      { REACT_APP_ENV !== 'prod'
        && (
          <WPCButton onAction={updateStatus} variant="primary">
            { t('actions.next') }
          </WPCButton>
        )}
      <Icon
        size={24}
        value={isOpen ? 'chevron-up-line' : 'chevron-down-line'}
        variant="default"
      />
    </button>
  );
};
