import type { FC } from 'react';
import { VtmnListItemEndAction } from '@vtmn/react';

type Props = {
  children: JSX.Element | string | JSX.Element[],
};

const ListItemEndAction: FC<Props> = ({ children }) => (
  <VtmnListItemEndAction>
    { children }
  </VtmnListItemEndAction>
);

export default ListItemEndAction;
