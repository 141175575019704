import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import { Icon } from '@/components';

const DetailSaleInformations: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.card_info}>
      <div className={classes.card_display}>
        <Icon value="shield-check-line" size={16} className={classes.icon_info} />
        <span className="vtmn-typo_text-3">{ t('product.infos.assurance') }</span>
      </div>
      <div className={classes.card_display}>
        <Icon value="award-line" size={16} className={classes.icon_info} />
        <span className="vtmn-typo_text-3">{ t('product.infos.product_reco') }</span>
      </div>
      <div className={classes.card_display}>
        <Icon value="store-line" size={16} className={classes.icon_info} />
        <span className="vtmn-typo_text-3">{ t('product.infos.retrait') }</span>
      </div>
    </div>
  );
};

export default DetailSaleInformations;
