import type { FC } from 'react';
import { useCallback, useState } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import type { LockerItem } from '@/types/Locker';
import { LOCKER_STATE } from '@/constants/locker';
import { ModalLocker } from '@/components';
import LigneContent from './Content/LigneContent';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { useTracking } from '@/hooks/useTracking';

type Props = {
  data: { [key: string]: LockerItem[] },
  icon: any,
  title: string,
  subtitle?: string,
  state: string,
  reloadEquipment: () => void;
};

export const Container: FC<Props> = ({
  data, icon, title, state, reloadEquipment, subtitle,
}) => {
  const { clickEvent } = useTracking();

  const [selectedProduct, setSelectedProduct] = useState<LockerItem | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const getBorderColor = useCallback((state: string) => (state === LOCKER_STATE.TO_RETURN ? styles.negative_border : styles.positive_border), []);

  const openProduct = useCallback((lockerItem: LockerItem) => {
    clickEvent(MATOMO_CATEGORY.LOCKER.OVERLAY);
    setSelectedProduct(lockerItem);
    setIsOpen(true);
  }, [clickEvent]);

  return (
    <>
      <div className={styles.container}>
        {Object.keys(data)?.map((date, index) => (
          <div key={`${index}key`} className={classnames(styles.block, getBorderColor(state))}>
            <LigneContent
              subtitle={subtitle}
              reloadEquipment={reloadEquipment}
              data={data}
              date={date}
              icon={icon}
              title={title}
              state={state}
              openProduct={openProduct}
            />
          </div>
        ))}
      </div>
      <ModalLocker
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
    </>
  );
};
