import type { FC } from 'react';
import Subscription from './Subscription';
import { CatalogProduct, Spacer } from '@/components';
import { useResponsive } from '@/hooks/useResponsive';

const Subscribe: FC = () => {
  const { isMobile } = useResponsive();

  return (
    <div>
      <Subscription />
      <Spacer vertical={isMobile ? 3 : 10} />
      <CatalogProduct />
    </div>
  );
};

export default Subscribe;
