import type { FC } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import classes from './styles.module.scss';

const SelectionHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <Grid item md={12}>
      <div className={classNames('vtmn-typo_text-3', classes.tabs)}>
        <Grid container>
          <Grid item md={1} xs={4} sm={2}>
            {t('locker.selection.tabs.picture')}
          </Grid>
          <Grid item md={6} className={classes.product_title}>
            {t('locker.selection.tabs.product')}
          </Grid>
          <Grid item md={3}>
            {t('locker.selection.tabs.price')}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default SelectionHeader;
