import type { FC } from 'react';
import { VtmnListItemText } from '@vtmn/react';

type Props = {
  children: JSX.Element | string | JSX.Element[],
  className: string,
};

const ListItemText: FC<Props> = ({ children, className }) => (
  <VtmnListItemText className={className}>
    { children }
  </VtmnListItemText>
);

export default ListItemText;
