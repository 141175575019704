import type { FC } from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import { Spacer, WPCButton, Conditions } from '@/components';
import SubscriptionList from './SubscriptionsList';
import type { LandingContent } from '@/types/Config';
import GetNbPlacesAvailableCase from '@/usecases/GetNbPlacesAvailableCase';
import type { Store } from '@/types/Store';
import GetStoresUseCase from '@/usecases/GetStoresUseCase';

export type SubscriptionChoice = {
  store: string;
  subType: string;
};

type SubscriptionProps = {
  selectedStore: string;
  selectedSub: number;
  onContinue: (choice: SubscriptionChoice) => void;
  content : LandingContent;
  setSelectedStore: (store: string) => void;
};

const Subscription: FC<SubscriptionProps> = ({
  selectedStore, selectedSub, onContinue, content, setSelectedStore,
}) => {
  const { t } = useTranslation();
  const [currentSubIndex, setCurrentSubIndex] = useState<number>(selectedSub);
  const [nbPlacesAvailable, setNbPlacesAvailable] = useState<number>(0);
  const [stores, setStores] = useState<Store[]>([]);

  useEffect(() => {
    (async () => {
      setStores(await GetStoresUseCase.execute());
    })();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        setNbPlacesAvailable(await GetNbPlacesAvailableCase.execute());
      } catch {
        setNbPlacesAvailable(0);
      }
    })();
  }, []);

  useEffect(() => {
    setCurrentSubIndex(selectedSub);
  }, [selectedSub]);

  const handleContinue = () => {
    const choice: SubscriptionChoice = {
      store: selectedStore,
      subType: content.subs[currentSubIndex].type,
    };
    onContinue(choice);
  };

  return (
    <div className={classes.root}>
      <p className="vtmn-typo_text-1 vtmn-font-bold">{t('onboarding.subscription.store')}</p>
      <Spacer vertical={0.5} />
      <p className="vtmn-typo_text-2">{t('onboarding.subscription.select_store')}</p>
      <Spacer vertical={2} />
      <div className={classes.stores}>
        {stores.map((store) => (
          // eslint-disable-next-line react/jsx-key
          <WPCButton
            className={classNames(selectedStore === store.id.toString() && classes.selected)}
            onAction={() => {
              setSelectedStore(store.id.toString());
            }}
            size="stretched"
          >
            {t(`onboarding.subscription.${store.id}`)}
          </WPCButton>
        ))}
      </div>
      <Spacer vertical={3} />
      <p className="vtmn-typo_text-1 vtmn-font-bold">{t('onboarding.subscription.subscription')}</p>
      <Spacer vertical={2} />
      <SubscriptionList
        nbPlacesAvailable={nbPlacesAvailable}
        subs={content.subs}
        onSelect={(index: number) => {
          setCurrentSubIndex(index);
        }}
        defaultSub={selectedSub}
      />
      <Spacer vertical={3} />
      <Conditions />
      <Spacer vertical={3} />
      <WPCButton
        onAction={handleContinue}
        size="stretched"
        variant="primary"
        iconRight="chevron-right-line"
        disabled
        // disabled={!selectedStore.length || currentSubIndex === -1 || nbPlacesAvailable === 0}
      >
        {t('actions.continue')}
      </WPCButton>
    </div>
  );
};

export default Subscription;
