import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Price } from '@/components';
import classes from './styles.module.scss';
import currencyFormatter from '@/utils/currencyFormatter';

type PriceProps = {
  realPrice: number,
  promotionalPrice?: number,
  displayLabel?: boolean,
};

const PromotionalPriceAndPrice : FC<PriceProps> = ({ realPrice, promotionalPrice, displayLabel = true }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {displayLabel && (
      <div className={classNames('vtmn-typo_text-3', classes.price_infos)}>
        {t('price.price')}
      </div>
      )}
      <div className={classes.prices}>
        {promotionalPrice
          ? (
            <Price
              size="small"
              variant="strikethrough"
              className={classes.promotional}
            >
              { currencyFormatter(realPrice) }
            </Price>
          ) : null}
        <Price variant={promotionalPrice ? 'alert' : 'accent'} className={classNames({ [classes.real_price]: !promotionalPrice })}>
          { currencyFormatter(promotionalPrice || realPrice) }
        </Price>
      </div>
    </div>
  );
};

export default PromotionalPriceAndPrice;
