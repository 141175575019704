import type { FC } from 'react';
import { VtmnListItem } from '@vtmn/react';

type Props = {
  children: JSX.Element | string | JSX.Element[],
  onClick: () => void;
};

const ListItem: FC<Props> = ({ children, onClick }) => (
  <VtmnListItem onClick={onClick}>
    { children }
  </VtmnListItem>
);

export default ListItem;
