import type React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.scss';
import { Spacer, Icon } from '@/components';
import State from '../State';

type Page2Props = {
  complete?: boolean;
  clean?: boolean;
  state?: boolean;
  setComplete: (value: boolean) => void;
  setClean: (value: boolean) => void;
  setState: (value: boolean) => void;
};

const Page2: React.FC<Page2Props> = ({
  complete,
  clean,
  state,
  setComplete,
  setClean,
  setState,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.help}>
        <div className={classes.icon}>
          <Icon
            value="error-warning-line"
            variant="positive"
            size={20}
          />
        </div>
        <span className="vtmn-typo_text-3">{t('return.help.title')}</span>
      </div>
      <Spacer vertical={3} />
      <span className="vtmn-typo_title-5">
        {t('return.state.title')}
      </span>
      <Spacer vertical={3} />
      <State mode="complete" current={complete} onSelect={setComplete} />
      <Spacer vertical={3} />
      <State mode="clean" current={clean} onSelect={setClean} />
      <Spacer vertical={3} />
      <State mode="state" current={state} onSelect={setState} />
    </div>
  );
};

export default Page2;
