import type { FC } from 'react';
import {
  useCallback, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useTracking } from '@/hooks/useTracking';
import classes from './styles.module.scss';
import LockerNavigation from './LockerNavigation';
import AccountNavigation from './AccountNavigation';
import { ROUTES } from '@/pages/routes';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { AuthState } from '@/context/Auth/AuthState';
import { AuthContext } from '@/context/Auth/AuthProvider';
import { LanguageSelector, WPCButton, Icon } from '@/components';
import { AuthRepository } from '@/repositories';
import { RightsContext } from '@/context/Rights/RightsProvider';
import Assets from '@/core/api/Assets';
import { RightsState } from '@/context/Rights/RightsState';
// import SearchBar from '@/components/TopBar/SearchBar';

const DecathlonLogo = Assets.getAssets('/general/decathlonLogo.svg');

const TopBar: FC = () => {
  const { clickEvent } = useTracking();
  const location = useLocation();
  const { t } = useTranslation();
  const { isActive, isUnknown } = useContext(RightsContext);
  const { isAuth } = useContext(AuthContext);

  const goToHome = useCallback(() => {
    clickEvent(MATOMO_CATEGORY.HEADER.HOME);
  }, [clickEvent]);

  const handleConnect = useCallback(() => {
    clickEvent(location.pathname === '/landing' ? MATOMO_CATEGORY.LANDING.LOGIN : MATOMO_CATEGORY.HEADER.LOGIN);
    AuthRepository.signinRedirect();
  }, [location.pathname, clickEvent]);

  const getPathToGo = () => {
    if (isAuth === AuthState.CONNECTED && isActive === RightsState.ACTIVE) {
      return ROUTES.HOME_PAGE;
    }
    return ROUTES.LANDING;
  };
  return (
    <div className={classNames(classes.topBar, (isAuth === undefined || isAuth === AuthState.DISCONNECTED) && classes.is_disconnected)}>
      <Link
        to={getPathToGo()}
        onClick={goToHome}
        className={['/landing', '/home'].includes(location.pathname) ? classes.no_pointer : classes.icon_text}
      >
        <img src={DecathlonLogo} alt="logo" />
      </Link>
      {/* {isActive === RightsState.ACTIVE && <SearchBar />} */}
      <div className={classes.endBar}>
        <div className={classes.faq}>
          <Link to={ROUTES.FAQ}>
            <Icon
              size={24}
              value="question-line"
              variant="default"
            />
          </Link>
        </div>
        <LanguageSelector />
        { (isAuth === undefined || isAuth === AuthState.DISCONNECTED)
          ? (
            <div className={classes.button_login}>
              <WPCButton
                onAction={handleConnect}
                size="stretched"
                variant="tertiary"
              >
                { t('actions.login') }
              </WPCButton>
            </div>
          )
          : (
            <div className={classes.endBar}>
              <AccountNavigation />
              {!isUnknown && <LockerNavigation /> }
            </div>
          )}
      </div>
    </div>
  );
};

export default TopBar;
