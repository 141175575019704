import type { FC } from 'react';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import type { SimpleModel } from '@/types/Model';
import classes from './styles.module.scss';
import { PromotionalPriceAndPrice } from '@/components';

type ModelInfoProps = {
  model: SimpleModel[],
};

const ModelInfo : FC<ModelInfoProps> = ({ model }) => (
  <>
    {model.map((model, index) => (
      <div key={index} className={classes.root}>
        <Grid container>
          <Grid item xs={4} sm={3} className={classes.image_model}>
            { model.img_url && <img src={model.img_url} alt="img_model" />}
          </Grid>
          <Grid item xs={8} sm={9} className={classes.product}>
            <div className="vtmn-typo_text-3 vtmn-font-bold">
              {model.brand}
            </div>
            <div className={classNames('vtmn-typo_caption-1', classes.label)}>
              { model.label } - { model.color_label}
            </div>
            <div className={classes.mobile_price}>
              { model.price && <PromotionalPriceAndPrice realPrice={model.price} displayLabel={false} /> }
            </div>
          </Grid>
        </Grid>
      </div>
    ))}
  </>
);

export default ModelInfo;
