import type { FC } from 'react';
import { Drawer, GenericInformations, Icon } from '@/components';
import type { PageInfo } from '@/types/Component';
import classes from '../styles.module.scss';
import { useResponsive } from '@/hooks/useResponsive';

type DrawerCardInfoProps = {
  drawerInfo?: PageInfo,
  isOpen: boolean,
  updateSwipeableDrawer: (openDrawer: boolean) => void,
};

const DrawerCardInfo: FC<DrawerCardInfoProps> = ({ drawerInfo, updateSwipeableDrawer, isOpen }) => {
  const { isMobile } = useResponsive();

  return (
    <Drawer
      isOpen={isOpen}
      updateDrawer={updateSwipeableDrawer}
      borderTopRightRadius={isMobile ? 16 : 0}
      borderTopLeftRadius={isMobile ? 16 : 0}
      anchor="right"
    >
      <div>
        { drawerInfo
          && (
          <div className={classes.root_drawer}>
            <div className={classes.first_line}>
              <div className="vtmn-typo_title-4 vtmn-font-bold">
                {drawerInfo.title}
              </div>
              <Icon
                value="close-line"
                variant="default"
                onClick={() => updateSwipeableDrawer(false)}
                size={24}
                className={classes.button_close}
              />
            </div>
            <GenericInformations informations={drawerInfo.elements} />
          </div>
          )}
      </div>
    </Drawer>
  );
};

export default DrawerCardInfo;
