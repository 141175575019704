import type { FC } from 'react';
import { Divider, MenuItem } from '@mui/material';

type Props = {
  isLast?: boolean,
  onClick: () => void,
  children: JSX.Element | string
};

const MenuItemAccount: FC<Props> = ({ isLast = false, onClick, children }) => (
  <div>
    <MenuItem sx={{ padding: 2, width: '400px' }} onClick={onClick}>
      {children}
    </MenuItem>
    {!isLast && <Divider />}
  </div>
);

export default MenuItemAccount;
