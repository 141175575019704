import type { FC } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import classes from './styles.module.scss';
import { Spacer, Subscription } from '@/components';

type CarouselProps = {
  subscriptions: any[];
  handleSelect: (index: number) => void;
  selectedIndex: number;
  nbPlacesAvailable: number;
};

const Carousel: FC<CarouselProps> = ({
  subscriptions, handleSelect, selectedIndex, nbPlacesAvailable,
}) => (
  <div className={classes.root}>
    <Swiper
      className={classes.swiper}
      slidesPerView={1.5}
      spaceBetween={10}
      slidesPerGroup={1}
      slidesOffsetBefore={20}
      slidesOffsetAfter={20}
      modules={[Navigation, Pagination]}
      pagination={{
        dynamicBullets: true,
      }}
    >
      {subscriptions.map((sub: any, index) => (
        <SwiperSlide key={sub.name}>
          <Subscription
            isSelectable={nbPlacesAvailable > 0}
            key={`sub_${sub.name}`}
            sub={sub}
            onSelect={() => handleSelect(index)}
            isSelected={selectedIndex === index}
          />
          <Spacer vertical={5} />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);

export default Carousel;
