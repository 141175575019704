import type { FC } from 'react';
import type { ReviewInformation } from '@/types/Reviews';
import classes from './styles.module.scss';
import ReviewProgressBar from './ReviewProgressBar';

type ReviewDetailProps = {
  review: ReviewInformation;
};

const ReviewDetail: FC<ReviewDetailProps> = ({ review }) => (
  <div className={classes.detail}>
    <div className={classes.detail_label}>
      { review.reviewsDetail?.map((reviewDetail, indexDetail) => (
        <div key={indexDetail} className={classes.label}>
          { reviewDetail.label }
        </div>
      ))}
    </div>
    <div className={classes.detail_progress_bar}>
      { review.reviewsDetail?.map((reviewDetail, indexDetail) => (
        <ReviewProgressBar rating={reviewDetail.rating} key={indexDetail} />
      ))}
    </div>
    <div className={classes.detail_rating}>
      { review.reviewsDetail?.map((reviewDetail, indexDetail) => (
        <div key={indexDetail}>
          { reviewDetail.rating }
        </div>
      ))}
    </div>
  </div>
);

export default ReviewDetail;
