import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import classes from './styles.module.scss';
import { PromotionalPriceAndPrice, Spacer, Icon } from '@/components';
import { useResponsive } from '@/hooks/useResponsive';
import { ROUTES } from '@/pages/routes';
import type { LockerItem } from '@/types/Locker';
import { MATOMO_CATEGORY } from '@/constants/matomo';
import { useTracking } from '@/hooks/useTracking';

type SelectionLigneProps = {
  item: LockerItem;
  openModalDelete: (uuid: string) => void;
};

const SelectionLigne : FC<SelectionLigneProps> = ({ item, openModalDelete }) => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();
  const { clickEvent } = useTracking();

  const deleteItem = useCallback(() => {
    clickEvent(MATOMO_CATEGORY.LOCKER.DELETE_ITEM);
    openModalDelete(item.uuid);
  }, [clickEvent, openModalDelete, item]);

  return (
    <Grid
      item
      md={12}
      className={classes.ligne}
    >
      <div className={classes.ligne_model}>
        <Grid container>
          <Grid
            item
            md={1}
            xs={4}
            sm={2}
            className={classes.image_model}
          >
            <Link to={`${ROUTES.PRODUCT.replace(':model_code', item.modelCode).replace(':super_model_code', item.superModelCode)}`}>
              { item.cover && <img src={item.cover} alt="img_model" />}
            </Link>
          </Grid>
          <Grid item xs={6} className={classNames(isMobile ? 'vtmn-typo_caption-1' : 'vtmn-typo_text-3', classes.product)}>
            <div className="vtmn-font-bold">{ item.brandLabel }</div>
            { isMobile && <Spacer vertical={0.5} />}
            <div className={classes.label}>
              { item.webLabel } - { item.colorLabel }
            </div>
            <div className="vtmn-typo_caption-1 vtmn-text-content-secondary">
              { t('locker.size', { size: item.size })}
            </div>
            { isMobile && (
            <div className={classes.mobile_price}>
              <PromotionalPriceAndPrice realPrice={item.price} displayLabel={false} />
            </div>
            )}
          </Grid>
          { !isMobile
            && (
            <Grid item md={3} className={classes.center_element}>
              <PromotionalPriceAndPrice realPrice={item.price} displayLabel={false} />
            </Grid>
            )}
          <Grid item xs={2} sm={4} md={2} className={classes.icon_part}>
            <button className={classes.icon_delete} onClick={deleteItem}>
              <Icon
                size={16}
                value="delete-bin-line"
                variant="default"
              />
            </button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default SelectionLigne;
